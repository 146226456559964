import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import {
  ButtonModule, ControlValueChangesDirective, DIALOG_DATA, DialogRef, IconModule,
  SliderControlComponent,
} from '@pinup-teams/common';
import { FormControl } from '@ngneat/reactive-forms';
import { ImageLoaderDirective } from '@pt/directives';

import { DialogData } from './models';

@Component({
  selector: 'pt-interview-viewer',
  standalone: true,
  imports: [
    ButtonModule,
    IconModule,
    ReactiveFormsModule,
    SliderControlComponent,
    ControlValueChangesDirective,
    ImageLoaderDirective,
  ],
  templateUrl: './interview-viewer.component.html',
  styleUrl: './interview-viewer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InterviewViewerComponent {
  private _data = inject<DialogData>(DIALOG_DATA);
  private _dialogRef = inject(DialogRef);

  imageData = this._data.image;
  zoomLevel = signal<number>(1);
  sliderControl = new FormControl<number>(this.zoomLevel() - 1);

  close(): void {
    this._dialogRef.close();
  }

  onSliderChange(value: number): void {
    this.zoomLevel.set(value + 1);
  }
}
