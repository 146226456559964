import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule, IconModule } from '@pinup-teams/common';

import { DeliveryViewComponent } from './delivery-view.component';
import { AcsComponent } from './acs/acs.component';
import { DhlComponent } from './dhl/dhl.component';
import { DpdComponent } from './dpd/dpd.component';
import { FedexComponent } from './fedex/fedex.component';
import { GapAkisExpressComponent } from './gap-akis-express/gap-akis-express.component';
import { InpostComponent } from './inpost/inpost.component';
import { InternationalComponent } from './international/international.component';
import { NovaPoshtaComponent } from './nova-poshta/nova-poshta.component';
import { PickupComponent } from './pickup/pickup.component';
import { UkrposhtaComponent } from './ukrposhta/ukrposhta.component';

@NgModule({
  declarations: [
    DeliveryViewComponent,
    PickupComponent,
    InternationalComponent,
    UkrposhtaComponent,
    NovaPoshtaComponent,
    FedexComponent,
    DhlComponent,
    DpdComponent,
    InpostComponent,
    AcsComponent,
    GapAkisExpressComponent,
  ],
  imports: [CommonModule, IconModule, TranslateModule, ButtonModule],
  exports: [DeliveryViewComponent],
})
export class DeliveryViewModule {
}
