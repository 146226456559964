<div class="pt-transactions-total-full">
  @if (showMore()) {
    <button
      class="pt-transactions-total-full__btn-hide"
      type="button"
      puButton
      uiType="quaternary"
      size="m"
      (click)="toggleShowMore()"
    >
      {{ 'transactions.total.btn.hideStatistic' | translate }}
    </button>

    @for (item of totals(); track item.id) {
      <section class="pt-transactions-total-full__item">
        <div class="pt-transactions-total-full__donut">
          <pu-donut-chart
            [gap]="item.sum1 && item.sum2 ? 4 : 0"
            [slices]="[{id: 1, color: item.sum ? item.color1 : 'var(--color-bg-4x)', percent: item.percent1},
            {id: 2, color: item.color2, percent: item.percent2}]"
          />

          <div class="pt-transactions-total-full__sums">
            <div class="pt-transactions-total-full__sum-container">
              <div
                class="pt-transactions-total-full__dot"
                [style.background-color]="item.color1"
              >
              </div>
              <div class="pt-transactions-total-full__sum">
                {{ item.sum1 }}
              </div>
            </div>

            <div class="pt-transactions-total-full__divider"></div>

            <div class="pt-transactions-total-full__sum-container">
              <div
                class="pt-transactions-total-full__dot"
                [style.background-color]="item.color2"
              >
              </div>
              <div class="pt-transactions-total-full__sum">
                {{ item.sum2 }}
              </div>
            </div>
          </div>
        </div>

        <div class="pt-transactions-total-full__content-container">
          <div class="pt-transactions-total-full__donut pt-transactions-total-full_sm">
            <pu-donut-chart
              [viewBox]="60"
              [borderSize]="5"
              [gap]="item.sum1 && item.sum2 ? 4 : 0"
              [slices]="[{id: 1, color: item.sum ? item.color1 : 'var(--color-bg-4x)', percent: item.percent1},
              {id: 2, color: item.color2, percent: item.percent2}]"
            />
          </div>

          <div class="pt-transactions-total-full__content">
            <h3 class="pt-transactions-total-full__header">
              {{ item.id }}
            </h3>

            <div class="pt-transactions-total-full__labels">
              <div class="pt-transactions-total-full__label-container">
                <div
                  class="pt-transactions-total-full__dot"
                  [style.background-color]="item.color1"
                >
                </div>
                <div class="pt-transactions-total-full__label">
                  {{ item.label1 }}
                </div>
              </div>

              <div class="pt-transactions-total-full__label-container">
                <div
                  class="pt-transactions-total-full__dot"
                  [style.background-color]="item.color2"
                >
                </div>
                <div class="pt-transactions-total-full__label">
                  {{ item.label2 }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-transactions-total-full__sums pt-transactions-total-full_sm">
          <div class="pt-transactions-total-full__sum">
            {{ item.sum1 }}
          </div>

          <div class="pt-transactions-total-full__sum">
            {{ item.sum2 }}
          </div>
        </div>
      </section>
    }
  } @else {
    <div class="pt-transactions-total-full__collapse">
      <div class="pt-transactions-total-full__collapse-total">
        {{ 'transactions.header.incomeTotal' | translate }}

        <div class="pt-transactions-total-full__collapse-sum">
          {{ incomeTotal() }}
          <pu-icon
            iconName="pin-coin"
            size="16"
          />
        </div>
      </div>

      <div class="pt-transactions-total-full__collapse-total">
        {{ 'transactions.header.expenseTotal' | translate }}

        <div class="pt-transactions-total-full__collapse-sum">
          {{ expenseTotal() }}
          <pu-icon
            iconName="pin-coin"
            size="16"
          />
        </div>
      </div>

      <button
        type="button"
        puButton
        uiType="quaternary"
        size="m"
        (click)="toggleShowMore()"
      >
        {{ 'transactions.total.btn.viewMore' | translate }}
      </button>
    </div>
  }
</div>
