import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';

import { Store } from '@ngrx/store';
import { Balance } from '@pt/models';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule, IconModule } from '@pinup-teams/common';
import { environment } from '@pt/environment';

import { BalanceActions } from '../../store';
import { ImageLoaderDirective } from '@pt/directives';

@Component({
  standalone: true,
  imports: [TranslateModule, IconModule, ButtonModule, ImageLoaderDirective],
  selector: 'pu-balance-card',
  templateUrl: './balance-card.component.html',
  styleUrls: ['./balance-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BalanceCardComponent {
  @Input() balance: Balance;

  assetsHost = environment.assetsHost;

  private _store = inject(Store);

  edit(balance: Balance) {
    this._store.dispatch(BalanceActions.getBalanceSkinsReq.action({ initiator: balance }));
  }
}
