import { Injectable } from '@angular/core';

import { DialogRef, DialogService } from '@pinup-teams/common';
import { Balance, Skin } from '@pt/models';

import { BalanceSkinFormComponent } from './balance-skin-form.component';

@Injectable()
export class BalanceSkinFormDialog {
  constructor(private _dialog: DialogService) {
  }

  open(skins: Skin[], balance: Balance): DialogRef {
    return this._dialog.open(BalanceSkinFormComponent, { skins, balance });
  }
}
