import { Data } from '@angular/router';

import { createAction, props } from '@ngrx/store';
import { createAsyncAction } from '@pinup-teams/common';

import { achievementsFeatureKey, AchievementsFilters } from './';
import { GetAchievementsReq, GetAchievementsRes } from '../models';

const init = createAction(`[${achievementsFeatureKey}]: Init`, props<{ routeData: Data }>());
const initFilters = createAction(`[${achievementsFeatureKey}]: Init filters`);
const routeChanged = createAction(`[${achievementsFeatureKey}]: Route changed`);
const setFilters = createAction(
  `[${achievementsFeatureKey}]: Set filters`,
  props<{ filters: Partial<AchievementsFilters> }>(),
);
const changeFilters = createAction(
  `[${achievementsFeatureKey}]: Change filters`,
  props<{ filters: Partial<AchievementsFilters> }>(),
);
const setUserId = createAction(
  `[${achievementsFeatureKey}]: Set userId`,
  props<{ userId: number }>(),
);
const getAchievementsReq = createAsyncAction<GetAchievementsReq, GetAchievementsRes>(
  `[${achievementsFeatureKey}]: Get achievements req`,
);

export const AchievementsActions = {
  init,
  initFilters,
  routeChanged,
  setFilters,
  changeFilters,
  filterChangeActions: [changeFilters],
  setUserId,
  getAchievementsReq,
};
