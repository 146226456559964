import { Observable, delay, of } from 'rxjs';
import { fillArr } from '@pinup-teams/common';
import { RewardedBy } from '@pt/models';
import { environment } from '@pt/environment';

import { GetAchievementRes } from '../models';

export function getRewardedByMock(count = 12, id = 1): RewardedBy[] {
  return fillArr(count, i => (id === 2
    ? {
      user: null,
      course: null,
      onboardingStage: null,
      rewardedFor: `Rewarded for ${i}`,
      rewardedCount: Math.floor(Math.random() * 5),
    }
    : {
      user: i % 3 === 0 ? { id: i, fullName: `User-${i}` } : null,
      course: i % 3 === 1 ? { name: `Course-${i}` } : null,
      onboardingStage: i % 3 === 2 ? { name: `Stage-${i}` } : null,
      rewardedFor: null,
      rewardedCount: Math.floor(Math.random() * 5),
    }));
}

export function getAchievementMock(id: number): Observable<GetAchievementRes> {
  return of({
    achievement: {
      id,
      name: `Achievement-${id}`,
      achievedCount: 7,
      image: {
        id,
        url: `${environment.assetsHost}/assets/images/mock-achievement.png`,
        name: `Achievement-${id}.png`,
      },
      rewardedBy: getRewardedByMock(12, id),
      description: 'Some <b>des</b>cription',
    },
  }).pipe(delay(500));
}
