import { Injectable } from '@angular/core';

import { DialogService } from '@pinup-teams/common';

import { P2pSharePolicyComponent } from './p2p-share-policy.component';

@Injectable()
export class P2pSharePolicyDialog {
  constructor(private _dialog: DialogService) {
  }

  open() {
    return this._dialog.open(P2pSharePolicyComponent);
  }
}
