import { safeMatchMedia } from "@tsparticles/engine";
const defaultFactor = 1,
  defaultReduce = 0,
  identity = 1;
export class MotionInstance {
  constructor(container, engine) {
    this._handleMotionChange = mediaQuery => {
      const container = this._container,
        motion = container.actualOptions.motion;
      if (!motion) {
        return;
      }
      if (mediaQuery.matches) {
        if (motion.disable) {
          container.retina.reduceFactor = defaultReduce;
        } else {
          container.retina.reduceFactor = motion.reduce.value ? identity / motion.reduce.factor : defaultFactor;
        }
      } else {
        container.retina.reduceFactor = 1;
      }
    };
    this._container = container;
    this._engine = engine;
  }
  async init() {
    const container = this._container,
      options = container.actualOptions.motion;
    if (!(options && (options.disable || options.reduce.value))) {
      container.retina.reduceFactor = 1;
      return;
    }
    const mediaQuery = safeMatchMedia("(prefers-reduced-motion: reduce)");
    if (!mediaQuery) {
      container.retina.reduceFactor = defaultFactor;
      return;
    }
    this._handleMotionChange(mediaQuery);
    const handleChange = () => {
      void (async () => {
        this._handleMotionChange(mediaQuery);
        try {
          await container.refresh();
        } catch {}
      })();
    };
    if (mediaQuery.addEventListener !== undefined) {
      mediaQuery.addEventListener("change", handleChange);
    } else if (mediaQuery.addListener !== undefined) {
      mediaQuery.addListener(handleChange);
    }
    await Promise.resolve();
  }
}