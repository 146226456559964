import { delay, Observable, of } from 'rxjs';

import { GetP2pLimitsRes } from '../models';

export function getP2PLimitsMock(): Observable<GetP2pLimitsRes> {
  return of({
    perOne: 10,
    perMonth: 100,
    approvalEnabled: false,
  }).pipe(delay(500));
}
