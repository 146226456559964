<ng-container *puLet="isActive$ | async as isActive">
  <ng-container *puLet="itemSubject | async as item">
    @if (hasSubItems$ | async) {
      <pu-expansion-panel
        class="nav-expansion-panel"
        [opened]="isActive"
        isTogglableByHeader
        renderMode="eager"
      >
        <div
          class="nav-item"
          [class.nav-item_active]="isActive"
        >
          @if (item.icon) {
            <pu-icon [iconName]="item.icon"/>
          }
          {{ item.label | translate }}
        </div>

        <ul
          class="nav-item__list"
          *puExpansionPanelContent
        >
          @for (subItem of item.subItems; track subItem.label) {
            <li class="nav-item__list-item">
              <pu-nav-item [item]="subItem"/>
            </li>
          }
        </ul>
      </pu-expansion-panel>
    } @else {
      <a
        class="nav-item"
        [routerLink]="item.link"
        [routerLinkActiveOptions]="{ exact: !!item.isRoot }"
        routerLinkActive="nav-item_active"
      >
        @if (item.icon) {
          <pu-icon
            class="nav-item__icon md:pu-icon_l"
            [iconName]="item.icon"
          />
        }
        {{ item.label | translate }}
      </a>
    }
  </ng-container>
</ng-container>
