import { Injectable } from '@angular/core';

import { DialogRef, DialogService } from '@pinup-teams/common';

import { ContactComponent } from './contact.component';

@Injectable()
export class ContactDialog {
  constructor(private _dialog: DialogService) {
  }

  open(): DialogRef {
    return this._dialog.open(ContactComponent);
  }
}
