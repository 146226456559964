import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit,
} from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { Observable, delay, of } from 'rxjs';
import { environment } from '@pt/environment';
import { RootState, UiSelectors } from '@pt/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GroupResolverFormBuilder } from '@ngneat/reactive-forms/lib/form-builder';
import { FormBuilder, FormControl, FormGroup } from '@ngneat/reactive-forms';
import {
  ButtonModule,
  CheckboxComponentModule,
  ControlType,
  DIALOG_DATA,
  DialogRef,
  EmojiModule,
  IconModule,
  NormalizeNumberDirective,
  SearchSelectModule,
  SimpleInputModule,
  SpinnerModule,
  TextAreaComponentModule,
  ToastService,
  ToastType,
} from '@pinup-teams/common';
import { Store } from '@ngrx/store';
import { getUsersMock } from '@pt/auth';
import { P2PLimits, UserProfile } from '@pt/models';
import { AvatarModule, UserProfileCardModule } from '@pt/components';

import { AccrueUserRes, AccrualUserForm } from './models';
import { BalanceActions } from '../balances/store';
import { P2pSharePolicyDialog } from './components/p2p-share-policy/p2p-share-policy.dialog';

export type AccrualType = 'admin' | 'site';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ButtonModule,
    IconModule,
    SimpleInputModule,
    ReactiveFormsModule,
    SearchSelectModule,
    AvatarModule,
    UserProfileCardModule,
    SpinnerModule,
    EmojiModule,
    TextAreaComponentModule,
    CheckboxComponentModule,
    NormalizeNumberDirective,
  ],
  selector: 'pu-accrual-user-form',
  templateUrl: './accrual-user-form.component.html',
  styleUrls: ['./accrual-user-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [P2pSharePolicyDialog],
})
export class AccrualUserFormComponent implements OnInit {
  isDarkTheme$ = this._store.select(UiSelectors.selectIsDarkTheme);
  controlTypes = ControlType;
  form: FormGroup<GroupResolverFormBuilder<AccrualUserForm & { isAgreed?: boolean }>>;
  isSaving = false;
  api: string;
  type: AccrualType;
  user: UserProfile;
  limits: P2PLimits | null;
  usersMock = getUsersMock();// TODO: import mocks for search-select component is kind of weird IMHO

  constructor(
    private _store: Store<RootState>,
    private _dialogRef: DialogRef,
    private _fb: FormBuilder,
    private _translate: TranslateService,
    private _http: HttpClient,
    private _cd: ChangeDetectorRef,
    private _toast: ToastService,
    private _policyDialog: P2pSharePolicyDialog,
    @Inject(DIALOG_DATA) public data: { type: AccrualType; user: UserProfile; limits?: P2PLimits },
  ) {
    this.type = data.type;
    this.user = data.user;
    this.limits = data.limits || null;
  }

  ngOnInit() {
    this.form = this._fb.group({
      user: [
        // @ts-ignore
        { value: <UserProfile>(this.user ? this.user : null), disabled: !!this.user },
        Validators.required,
      ],
      amount: [1, Validators.required],
      comment: '',
    });

    if (this.type === 'site') {
      this.form.addControl('isAgreed', new FormControl(false, Validators.requiredTrue));
    }
  }

  get maxAmount(): number | null {
    return this.type === 'site' && !this.limits?.approvalEnabled ? this.limits?.perOne : null;
  }

  accrue() {
    this.isSaving = true;

    this.api = this.type === 'admin' ? 'emission/admin/manual/user/' : 'banking/share/';

    const { user, isAgreed, ...value } = this.form.getRawValue();

    const userAccrual = {
      userId: user.id,
      ...value,
    };

    let accrueUser$: Observable<AccrueUserRes>;

    if (environment.useMocks) {
      accrueUser$ = of(null).pipe(delay(500));
    } else {
      accrueUser$ = this._http.post<AccrueUserRes>(
        `${environment.apiHost}${this.api}`,
        userAccrual,
      );
    }

    accrueUser$.subscribe({
      next: () => {
        this._dialogRef.close(true);

        if (this.type === 'site') {
          this._store.dispatch(BalanceActions.getBalanceReq.action());
        }
      },
      error: error => {
        this.isSaving = false;
        this._cd.detectChanges();

        if (error.code === 'transfer_limit') {
          this._toast.show({
            type: ToastType.Error,
            iconName: 'pin-coin',
            message: this._translate.instant(
              'site.accrualUserForm.toast',
              { remainingLimit: error.data.remainingLimit },
            ),
          });
        }
      },
    });
  }

  openPolicy() {
    this._policyDialog
      .open()
      .afterClosed()
      .subscribe(isAgreed => {
        if (isAgreed) {
          this.form.controls.isAgreed.setValue(true);
        }
      });
  }

  close(): void {
    this._dialogRef.close();
  }
}
