import { combineReducers, createFeature, createReducer, on } from '@ngrx/store';
import { createAsyncReducer } from '@pinup-teams/common';

import { supportFeatureKey, SupportState } from './support.state';
import { SupportActions } from './support.actions';
import { GetTagsRes } from '../models';

const tagsReducer = createReducer<GetTagsRes>(
  { itemsCount: 0, tags: [] },
  on(SupportActions.getTagsReq.succeededAction, (state, action): GetTagsRes => action.payload),
);

const tagIdReducer = createReducer<number>(
  null,
  on(SupportActions.tagId, (state, action): number => action.tagId),
);

const tipTypeReducer = createReducer<string>(
  null,
  on(SupportActions.tipType, (state, action): string => action.tipType),
);

export const supportFeature = createFeature({
  name: supportFeatureKey,
  reducer: combineReducers<SupportState>({
    tags: tagsReducer,
    tagId: tagIdReducer,
    tipType: tipTypeReducer,
    getTagsAction: createAsyncReducer(SupportActions.getTagsReq),
    getTipsAction: createAsyncReducer(SupportActions.getTipsReq),
    sendMessageAction: createAsyncReducer(SupportActions.sendMessageReq),
  }),
});
