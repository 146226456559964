import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgTemplateOutlet } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { AchievementDialog } from '@pt/models';
import { AchievementComponent } from '@pt/components';
import {
  ButtonModule, DIALOG_DATA, DialogRef, FroalaModule, IconModule,
} from '@pinup-teams/common';

interface DialogData {
  achievement: AchievementDialog;
}

@Component({
  standalone: true,
  imports: [
    NgTemplateOutlet,
    RouterModule,
    TranslateModule,
    ButtonModule,
    IconModule,
    AchievementComponent,
    FroalaModule,
  ],
  selector: 'pu-achievement-dialog',
  templateUrl: './achievement-dialog.component.html',
  styleUrls: ['./achievement-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AchievementDialogComponent {
  private _dialogRef = inject(DialogRef);
  private _data = inject<DialogData>(DIALOG_DATA);

  achievement = this._data.achievement;
  hasRewardedFor = this.achievement.rewardedBy.some(({ rewardedFor }) => !!rewardedFor);
  hasRewardedBy = this.achievement.rewardedBy.some(({ user, course, onboardingStage }) => !!user
    || !!course || !!onboardingStage);

  close(): void {
    this._dialogRef.close();
  }
}
