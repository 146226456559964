<div class="pt-donut-total">
  <div class="pt-donut-total__donut-content">
    <pu-donut-chart
      [viewBox]="60"
      [borderSize]="5"
      [gap]="sum1() && sum2() ? 4 : 0"
      [slices]="donutData().slices"
    />

    <div class="pt-donut-total__content">
      @if (i18nTotalTitle()) {
        <h3 class="pt-donut-total__header">
          {{ i18nTotalTitle() | translate }}
          <div class="pt-donut-total__header-sum">
            {{ donutData().sum }}
            @if (totalIconName()) {
              <pu-icon
                [iconName]="totalIconName()"
                size="16"
              />
            }
          </div>
        </h3>
      }

      <div class="pt-donut-total__total-part">
        <div class="pt-donut-total__label-container">
          <div
            class="pt-donut-total__dot"
            [style.background-color]="color1()"
          >
          </div>
          <div class="pt-donut-total__label">
            {{ i18nLabel1() | translate }}
          </div>
        </div>

        <div class="pt-donut-total__total-part-sum">
          {{ sum1() }}
        </div>
      </div>

      <div class="pt-donut-total__total-part">
        <div class="pt-donut-total__label-container">
          <div
            class="pt-donut-total__dot"
            [style.background-color]="color2()"
          >
          </div>
          <div class="pt-donut-total__label">
            {{ i18nLabel2() | translate }}
          </div>
        </div>

        <div class="pt-donut-total__total-part-sum">
          {{ sum2() }}
        </div>
      </div>
    </div>
  </div>

  @if (i18nTotalTitle()) {
    <h3 class="pt-donut-total__header pt-donut-total_md">
      {{ i18nTotalTitle() | translate }}:
      <div class="pt-donut-total__header-sum">
        {{ donutData().sum }}
        @if (totalIconName()) {
          <pu-icon
            [iconName]="totalIconName()"
            size="16"
          />
        }
      </div>
    </h3>
  }
</div>
