<form
  class="pu-awards-form__form"
  (ngSubmit)="send()"
>
  <ul
    class="pu-awards-form__users"
    *ngIf="teamAwards.length"
  >
    <li
      class="pu-awards-form__user"
      *ngFor="let group of awardsForm.controls; trackBy: trackByIndex; let i = index"
    >
      <div class="pu-awards-form__user-profile">
        <a
          [routerLink]="['/colleague', group.value.colleagueId]"
          (click)="close()"
        >
          <pu-user-profile-card
            [user]="teamAwards[i].colleague"
            [hasEmail]="false"
          />
        </a>

        <pu-select
          [formControl]="group.controls.awardId"
          [items]="teamAwards[i].awards"
          [label]="'personalInfo.teamBonuses.awardsForm.selectAward' | translate"
        />
      </div>

      <button
        class="pu-awards-form__user-btn"
        type="button"
        puButton
        size="s"
        uiType="secondary"
        (click)="toggleComment(i)"
      >
        {{ 'personalInfo.teamBonuses.awardsForm.button.' + (commentsState[i]
        ? 'hideComment'
        : 'addComment') | translate }}
      </button>

      <div
        class="pu-awards-form__user-comment"
        *ngIf="commentsState[i]"
      >
        <pu-text-area
          [maxLength]="200"
          [rows]="3"
          [formControl]="group.controls.comment"
          [label]="'personalInfo.teamBonuses.awardsForm.comment' | translate"
        />

        <div class="pu-awards-form__user-comment-symbols">
          {{ 'personalInfo.teamBonuses.awardsForm.symbols' | translate }} {{
            group.value.comment.length }}/200
        </div>
      </div>
    </li>
  </ul>

  <div class="pu-awards-form__actions">
    <button
      type="submit"
      puButton
      size="l"
      uiType="primary"
      [disabled]="awardsForm.invalid"
    >
      {{ 'personalInfo.teamBonuses.awardsForm.button.sendAward' | translate }}
    </button>

    <button
      type="button"
      puButton
      size="l"
      uiType="secondary"
      (click)="close()"
    >
      {{ 'personalInfo.teamBonuses.awardsForm.button.cancel' | translate }}
    </button>
  </div>
</form>
