import { createSelector } from '@ngrx/store';
import { RubricTag } from '@pt/models';

import { supportFeature } from './support.reducers';

const {
  selectTags,
  selectTagId,
  selectTipType,
  selectGetTagsAction,
  selectGetTipsAction,
  selectSendMessageAction,
} = supportFeature;

export interface SupportViewModel {
  tags: RubricTag[];
  tagId: number;
  tipType: string;
  isLoading: boolean;
}

const selectViewModel = createSelector(
  selectTags,
  selectTagId,
  selectTipType,
  selectGetTagsAction,
  selectGetTipsAction,
  selectSendMessageAction,
  (tags, tagId, tipType, getTagsAction, getTipsAction, sendMessageAction): SupportViewModel => ({
    tags: tags.tags.map(tag => ({ ...tag, code: tag.name.trim().replace(' ', '_').toLowerCase() })),
    tagId,
    tipType,
    isLoading: getTagsAction.inProgress || getTipsAction.inProgress || sendMessageAction.inProgress,
  }),
);

export const SupportSelectors = {
  selectTags,
  selectTagId,
  selectTipType,
  selectGetTagsAction,
  selectGetTipsAction,
  selectSendMessageAction,
  selectViewModel,
};
