<ng-container *puLet="vm$ | async as vm">
  <div class="pu-team-bonuses__header">
    <h4 class="pu-team-bonuses__header-text">
      {{ 'personalInfo.teamBonus.header' | translate }}
    </h4>

    <button
      class="pu-team-bonuses__close"
      type="button"
      puButton
      size="m"
      uiType="quaternary"
      [onlyIcon]="true"
      (click)="close()"
    >
      <pu-icon
        iconName="close"
        size="16"
      />
    </button>
  </div>

  <div class="pu-team-bonuses__body">
    <div class="pu-team-bonuses__tabs">
      <button
        class="pu-team-bonuses__tab"
        *ngFor="let tab of tabs; trackBy: trackById"
        [class.pu-button_pressed]="type === tab.id"
        type="button"
        puButton
        uiType="gradient"
        size="s"
        [innerHTML]="tab.name"
        (click)="setType(tab.id)"
      >
      </button>
    </div>

    <ng-container
      *ngIf="vm.teamBonus && vm.teamAwards"
      [ngSwitch]="type"
    >
      <pu-bonus-form
        *ngSwitchCase="'bonus'"
        [functionalUsers]="vm.teamBonus.functionalUsers"
        [bonusTotal]="vm.teamBonus.amount"
      />

      <pu-awards-form
        *ngSwitchCase="'awards'"
        [teamAwards]="vm.teamAwards"
      />
    </ng-container>
  </div>
</ng-container>
