import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PersonalInfoAdditionalInfo } from '@pt/models';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'pu-user-additional-info',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './user-additional-info.component.html',
  styleUrls: ['./user-additional-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAdditionalInfoComponent {
  @Input() additionalInfo: PersonalInfoAdditionalInfo;
}
