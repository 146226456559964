<div class="pt-text-total">
  <h3 class="pt-text-total__header">
    @if (i18nHeader()) {
      {{ i18nHeader() | translate }}:
    }
    <div class="pt-text-total__header-sum">
      {{ sum() }}
      @if (iconName()) {
        <pu-icon
          [iconName]="iconName()"
          size="16"
        />
      }
    </div>
  </h3>
</div>
