<div class="pu-delivery-view__item">
  <div class="pu-delivery-view__container">
    <dt>{{ 'delivery.location' | translate }}</dt>
    <dd>{{ delivery.location }}</dd>
  </div>

  <div class="pu-delivery-view__container">
    <dt>{{ 'delivery.address' | translate }}</dt>
    <dd>{{ delivery.address }}</dd>
  </div>
</div>
