export function drawEmoji(data) {
  const {
      context,
      particle,
      radius,
      opacity
    } = data,
    emojiData = particle.emojiData,
    double = 2,
    diameter = radius * double,
    previousAlpha = context.globalAlpha;
  if (!emojiData) {
    return;
  }
  context.globalAlpha = opacity;
  context.drawImage(emojiData, -radius, -radius, diameter, diameter);
  context.globalAlpha = previousAlpha;
}