import { DonutTotal } from '../models';

export function getDonutTotal(
  id: string,
  sum1: number,
  color1: string,
  label1: string,
  sum2: number,
  color2: string,
  label2: string,
): DonutTotal {
  const sum = sum1 + sum2;
  let percent1 = 99.99;
  let percent2 = 0.01;

  if (sum) {
    percent1 = sum1 ? (sum2 ? Math.round((sum1 * 100) / sum) : 99.99) : 0.01;
    percent2 = sum2 ? (sum1 ? 100 - percent1 : 99.99) : 0.01;
  }

  return {
    id,
    sum,
    sum1,
    color1,
    label1,
    percent1,
    sum2,
    color2,
    label2,
    percent2,
  };
}
