import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DeliveryDpdForm } from '@pt/models';

@Component({
  selector: 'pu-delivery-view-dpd',
  templateUrl: './dpd.component.html',
  styleUrls: ['./dpd.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DpdComponent {
  @Input() delivery: DeliveryDpdForm;
}
