<div *puLet="isDarkTheme$ | async as isDarkTheme" class="pt-not-found__body">
  <img
    class="pt-not-found__symbol"
    [src]="isDarkTheme ? 'assets/404/404-white.svg' : 'assets/404/404-black.svg'"
    alt="not found"
  />

  <p
    class="pt-not-found__title"
    translate="notFoundPage.title"
  >
  </p>

  <p
    class="pt-not-found__description"
    translate="notFoundPage.description"
  >
  </p>

  <a
    puButton
    uiType="secondary"
    class="pt-not-found__btn xl:pu-button_l"
    [routerLink]="'/'"
    size="l"
    translate="notFoundPage.backToHomePage"
  >
  </a>
</div>
