import {
  booleanAttribute, ChangeDetectionStrategy, Component, HostBinding, inject, Input,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { Store } from '@ngrx/store';
import { UiSelectors } from '@pt/store';

// TODO: Using this component refactor scroll shadows on every page
@Component({
  standalone: true,
  selector: 'pt-scroll-shadow',
  template: '',
  styleUrls: ['./scroll-shadow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollShadowComponent {
  private _store = inject(Store);

  @Input({ transform: booleanAttribute }) alwaysVisible = false;

  @HostBinding('class.pt-scroll-shadow_visible') get visibleShadowClass() {
    return this.isShadowVisible() || this.alwaysVisible;
  }

  isShadowVisible = toSignal(this._store.select(UiSelectors.selectIsShadowVisibleByScroll));
}
