import { AsyncState } from '@pinup-teams/common';
import { AchievementType, AchievementStatus } from '@pt/models';

import { GetAchievementsRes } from '../models';

export const achievementsFeatureKey = 'Achievements History Page';

export interface AchievementsFilters {
  page: number;
  limit: number;
  period: number | null;
  achievementType: AchievementType | null;
  status: AchievementStatus | null;
}

export interface AchievementsState {
  filters: AchievementsFilters;
  userId: number;
  achievements: GetAchievementsRes;
  getAchievementsAction: AsyncState<GetAchievementsRes>;
}
