import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DialogService } from '@pinup-teams/common';
import { UserProfile } from '@pt/models';
import { environment } from '@pt/environment';
import { switchMap } from 'rxjs';

import { AccrualType, AccrualUserFormComponent } from './accrual-user-form.component';
import { getP2PLimitsMock } from './mocks';
import { GetP2pLimitsRes } from './models';

@Injectable({
  providedIn: 'root',
})
export class AccrualUserFormDialog {
  constructor(private _dialog: DialogService, private _http: HttpClient) {
  }

  open(type: AccrualType, user?: UserProfile): void {
    if (type === 'admin') {
      this._dialog.open(AccrualUserFormComponent, { type, user });

      return;
    }

    const limitsStream$ = environment.useMocks
      ? getP2PLimitsMock()
      : this._http.get<GetP2pLimitsRes>(environment.apiHost + 'banking/admin/transfer-limits/');

    // takeUntil approach is not necessary as .afterClosed() completes stream
    limitsStream$.pipe(switchMap(limits => this._dialog.open(
      AccrualUserFormComponent,
      { type, user, limits },
    ).afterClosed())).subscribe();
  }
}
