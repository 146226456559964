import { booleanAttribute, ChangeDetectionStrategy, Component, input, output } from '@angular/core';

import { IconModule } from '@pinup-teams/common';

@Component({
  standalone: true,
  selector: 'pt-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconModule],
})
export class SwitcherComponent {
  isDarkTheme = input<boolean, unknown>(false, { transform: booleanAttribute });
  clicked = output<void>();
}
