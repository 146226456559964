import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { AchievementsHistoryComponent } from './achievements-history.component';
import { achievementsFeature, AchievementsEffects } from './store';

const routes: Routes = [
  {
    path: '',
    component: AchievementsHistoryComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    StoreModule.forFeature(achievementsFeature),
    EffectsModule.forFeature([AchievementsEffects]),
    AchievementsHistoryComponent,
  ],
  exports: [AchievementsHistoryComponent],
})
export class AchievementsHistoryModule {
}
