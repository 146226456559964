import { booleanAttribute, ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { RouterLink } from '@angular/router';

import { UserProfileCardModule } from '@pt/components';
import { UserProfile } from '@pt/models';
import { ButtonModule, IconModule } from '@pinup-teams/common';
import { TranslateModule } from '@ngx-translate/core';

// TODO Perhaps can be reused in birthdays page
@Component({
  selector: 'pt-user-action-card',
  standalone: true,
  imports: [ButtonModule, IconModule, RouterLink, TranslateModule, UserProfileCardModule],
  templateUrl: './user-action-card.component.html',
  styleUrl: './user-action-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserActionCardComponent {
  btnClick = output<void>();
  btnLabel = input<string>();
  iconName = input('gift');
  isActionDisabled = input<boolean, unknown>(false, { transform: booleanAttribute });
  user = input.required<UserProfile>();
}
