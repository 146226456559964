import { AsyncState } from '@pinup-teams/common';

import { GetTagsRes, GetTipsRes, SendMessageRes } from '../models';

export const supportFeatureKey = 'Support';

export interface SupportState {
  tags: GetTagsRes;
  tagId: number;
  tipType: string;
  getTagsAction: AsyncState<GetTagsRes>;
  getTipsAction: AsyncState<GetTipsRes>;
  sendMessageAction: AsyncState<SendMessageRes>;
}
