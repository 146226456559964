import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DeliveryPickupForm } from '@pt/models';

@Component({
  selector: 'pu-delivery-view-pickup',
  templateUrl: './pickup.component.html',
  styleUrls: ['./pickup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PickupComponent {
  @Input() delivery: DeliveryPickupForm;
}
