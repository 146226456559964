<label class="pt-switcher">
  <input
    type="checkbox"
    (click)="clicked.emit()"
  >
  <div class="pt-switcher__slider">
    <pu-icon
      size="20"
      [iconName]="isDarkTheme() ? 'light' : 'dark'"
    />
  </div>
</label>
