import { AsyncState } from '@pinup-teams/common';
import { TransactionGroup, TransactionStatus } from '@pt/models';

import { GetTransactionsRes } from '../models';

export const transactionsFeatureKey = 'Transactions Page';

export interface TransactionsFilters {
  page: number;
  limit: number;
  period: number | null;
  group: TransactionGroup | null;
  status: TransactionStatus | null;
}

export interface TransactionsState {
  filters: TransactionsFilters;
  userId: number;
  transactions: GetTransactionsRes;
  getTransactionsAction: AsyncState<GetTransactionsRes>;
}
