import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { environment } from '@pt/environment';
import { delay, Observable, of, switchMap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import {
  createChangeRouteEffect, createRouteFiltersEffect, createAsyncEffect, RouteFiltersSettings,
} from '@pinup-teams/common';
import { RootState } from '@pt/store';
import { getQueryParams } from '@pinup-teams/common';

import {
  AchievementsActions, AchievementsSelectors, AchievementsFilters, initialFilters,
} from './';
import { GetAchievementsReq, GetAchievementsRes } from '../models';
import { getAchievementsMock } from '../mocks';

export const routeFiltersSettings: RouteFiltersSettings<AchievementsFilters> = {
  ignoreRoute: true,
  initialFilters,
  actions: AchievementsActions,
  selectors: AchievementsSelectors,
  queryParams: ['page', 'limit', 'period', 'achievementType:string', 'status:string'],
};

@Injectable()
export class AchievementsEffects {
  private readonly _actions$ = inject(Actions);
  private readonly _store = inject(Store<RootState>);
  private readonly _router = inject(Router);
  private readonly _http = inject(HttpClient);

  readonly init$ = createEffect(() => this._actions$.pipe(
    ofType(AchievementsActions.init),
    switchMap(action => [
      AchievementsActions.setUserId({ userId: action.routeData['userId'] }),
      AchievementsActions.initFilters(),
    ]),
  ));
  readonly routeFilters$ = createRouteFiltersEffect<AchievementsFilters>(
    <any> this,
    routeFiltersSettings,
  );
  readonly changeRoute$ = createChangeRouteEffect<AchievementsFilters>(
    <any> this,
    routeFiltersSettings,
  );

  readonly changeFilters$ = createEffect(() => this._actions$.pipe(
    ofType(AchievementsActions.changeFilters),
    concatLatestFrom(() => [this._store.select(AchievementsSelectors.selectFilters)]),
    switchMap(([, filters]) => [
      AchievementsActions.getAchievementsReq.action({ payload: filters }),
    ]),
  ));

  readonly getAchievementsReq$ = createEffect(() => this._actions$.pipe(
    ofType(AchievementsActions.getAchievementsReq.action),
    concatLatestFrom(() => [this._store.select(AchievementsSelectors.selectUserId)]),
    switchMap(([{ payload }, userId]) => createAsyncEffect(
      this.getAchievements(payload, userId),
      AchievementsActions.getAchievementsReq,
    )),
  ));

  getAchievements(params: GetAchievementsReq, userId?: number): Observable<GetAchievementsRes> {
    if (environment.useMocks) {
      const userAchievements = getAchievementsMock(12);

      return of({
        sentTotal: 3000,
        receivedTotal: 2000,
        itemsCount: userAchievements.length,
        userAchievements,
      }).pipe(delay(500));
    } else {
      const api = environment.apiHost + (userId
        ? `achievements/admin/history/${userId}/`
        : 'achievements/history/');

      return this._http.get<GetAchievementsRes>(api, { params: getQueryParams(params) });
    }
  }
}
