<div class="pu-delivery-view__item">
  <div class="pu-delivery-view__container">
    <dt>{{ 'delivery.country' | translate }}</dt>
    <dd>{{ delivery.country }}</dd>
  </div>

  <div class="pu-delivery-view__container">
    <dt>{{ 'delivery.city' | translate }}</dt>
    <dd>{{ delivery.city }}</dd>
  </div>

  <div class="pu-delivery-view__container">
    <dt>{{ 'delivery.postOffice' | translate }}</dt>
    <dd>{{ delivery.postOffice || '-' }}</dd>
  </div>

  <div class="pu-delivery-view__container">
    <dt>{{ 'delivery.postTerminal' | translate }}</dt>
    <dd>{{ delivery.postTerminal || '-' }}</dd>
  </div>
</div>
