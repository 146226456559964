import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DeliveryDhlForm } from '@pt/models';

@Component({
  selector: 'pu-delivery-view-dhl',
  templateUrl: './dhl.component.html',
  styleUrls: ['./dhl.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DhlComponent {
  @Input() delivery: DeliveryDhlForm;
}
