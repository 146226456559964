<div class="pt-progress-bar__text">
  @if (title) {
    <div class="pt-progress-bar__text-title">{{ title }}</div>
  }
  <ng-content/>
</div>

<div class="pt-progress-bar__bar-container">
  <div
    class="pt-progress-bar__bar"
    [style.width.%]="progress"
  ></div>
</div>
