import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonModule, IconModule, LetModule, SliderControlsModule, SpinnerModule,
} from '@pinup-teams/common';
import { EffectsModule } from '@ngrx/effects';
import { SwiperModule } from 'swiper/angular';
import { TransactionsDialog } from '@pt/components';

import { BalancesComponent } from './balances.component';
import { BalanceEffects, balanceFeature } from './store';
import { BalanceCardComponent } from './components/balance-card/balance-card.component';
import { BalanceSkinFormDialog } from './components/balance-skin-form/balance-skin-form.dialog';

@NgModule({
  declarations: [BalancesComponent],
  imports: [
    CommonModule,
    RouterModule,
    IconModule,
    TranslateModule,
    ButtonModule,
    LetModule,
    SpinnerModule,
    SliderControlsModule,
    BalanceCardComponent,
    SwiperModule,
    StoreModule.forFeature(balanceFeature),
    EffectsModule.forFeature([BalanceEffects]),
  ],
  exports: [BalancesComponent],
  providers: [BalanceSkinFormDialog, TransactionsDialog],
})
export class BalancesModule {
}
