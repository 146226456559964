import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SupportComponent } from './support.component';
import { supportFeature, SupportEffects } from './store';
import { TipsDialog } from './components/tips/tips.dialog';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(supportFeature),
    EffectsModule.forFeature([SupportEffects]),
    SupportComponent,
  ],
  exports: [SupportComponent],
  providers: [TipsDialog],
})
export class SupportModule {
}
