import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DeliveryInpostForm } from '@pt/models';

@Component({
  selector: 'pu-delivery-view-inpost',
  templateUrl: './inpost.component.html',
  styleUrls: ['./inpost.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InpostComponent {
  @Input() delivery: DeliveryInpostForm;
}
