<ng-container *puLet="vm$ | async as vm">
  <ng-container *puLet="breakpoints$ | async as breakpoints">
    <div
      class="pt-transactions"
      *puSpinner="vm.isLoading"
    >

      <div class="pt-transactions__header">
        <h1 class="pt-transactions__h1">
          {{ 'transactions.title' | translate }}
        </h1>
        <div class="pt-transactions__filters">
          @if (forTeam()) {
            <swiper
              class="pt-transactions__bonus-tabs pu-swiper-gap-16"
              [config]="swiperConfig"
            >
              @for (status of teamBonusStatuses | withAllOption; track status.id) {
                <button
                  [class.pu-button_pressed]="filtersForm.controls.status.value === status.id"
                  *swiperSlide
                  type="button"
                  puButton
                  uiType="gradient"
                  size="s"
                  (click)="changeStatus(status)"
                >
                  {{ status.name }}
                </button>
              }
            </swiper>
          } @else {
            <div class="pt-transactions__tabs">
              @for (group of groups | withAllOption; track group.id) {
                <button
                  [class.pu-button_pressed]="filtersForm.controls.group.value === group.id"
                  type="button"
                  puButton
                  uiType="gradient"
                  size="s"
                  (click)="changeGroup(group)"
                >
                  {{ group.name }}
                </button>
              }
            </div>
          }

          <div class="pt-transactions__filters-controls">
            @if (filtersForm.controls.group.value === 'income') {
              <pu-select
                class="pt-transactions__filters-controls-select"
                [formControl]="filtersForm.controls.status"
                [items]="incomeStatuses | withAllOption"
                [label]="'transactions.filters.status' | translate"
              />
            }

            @if (filtersForm.controls.group.value === 'expense') {
              <pu-select
                class="pt-transactions__filters-controls-select"
                [formControl]="filtersForm.controls.status"
                [items]="expenseStatuses | withAllOption"
                [label]="'transactions.filters.status' | translate"
              />
            }

            <pu-select
              class="pt-transactions__filters-controls-select"
              [formControl]="filtersForm.controls.period"
              [items]="periods"
              [label]="'transactions.filters.period' | translate"
            />
          </div>
        </div>

        @if (dialog()) {
          <div class="pu-shadow-dialog pu-shadow-dialog_visible"></div>
        } @else {
          <div
            class="pu-scroll-shadow"
            [class.pu-scroll-shadow_visible]="vm.isShadowVisible"
          >
          </div>
        }
      </div>

      <ul class="pt-transactions__transactions">
        @for (transaction of vm.transactions; track transaction.id) {
          <li
            class="pt-transactions__transaction"
            ptTransaction
            [transaction]="transaction"
          >
          </li>
        } @empty {
          @if (!vm.isLoading) {
            <pu-empty
              iconName="transactions"
              titleKey="transactions.empty.title"
              btnKey="transactions.empty.controls.transactionsPage"
              [btnLink]="project === 'pt' ? '/transactions' : ''"
              [secondaryBtnLink]="project === 'pt' ? '/' : ''"
            />
          }
        }
      </ul>

      @if (vm.itemsCount > vm.filters.limit) {
        <div class="pt-transactions__paginator">
          <mat-paginator
            [length]="vm.itemsCount"
            [pageSize]="vm.filters.limit"
            [showFirstLastButtons]="true"
            [pageSizeOptions]="[30, 50, 100]"
            [hidePageSize]="true"
            [pageIndex]="vm.filters.page - 1"
            (page)="changePage($event)"
            aria-label="Select page"
          />
        </div>
      }

      @if (vm.itemsCount) {
        <div
          class="pt-transactions__total"
          [class.pt-transactions__total_navbar-hidden]="!dialog() && vm.isNavbarHidden && !breakpoints.isDesktopMode"
        >

          @if (forTeam()) {
            @if (vm.filters.status === null) {
              <div class="pt-transactions__two-donuts">
                <pt-donut-total
                  class="pt-transactions__two-donuts-first"
                  [sum1]="vm.total.received"
                  color1="var(--color-brand-secondary)"
                  i18nLabel1="transactions.label.receivedTotal"
                  [sum2]="vm.total.sent"
                  color2="var(--color-brand-primary)"
                  i18nLabel2="transactions.label.sentTotal"
                />

                <pt-donut-total
                  class="pt-transactions__two-donuts-second"
                  [sum1]="vm.total.received"
                  color1="var(--color-brand-secondary)"
                  i18nLabel1="transactions.label.receivedTotal"
                  [sum2]="vm.total.burned"
                  color2="var(--color-additionals-warning)"
                  i18nLabel2="transactions.label.burnedTotal"
                />
              </div>
            }
          } @else {
            @if (vm.filters.group === null) {
              <pt-transactions-total-full [total]="vm.total"/>
            }

            @if (vm.filters.group && vm.filters.status === null) {
              @switch (vm.filters.group) {
                @case (TransactionGroup.Income) {
                  <pt-donut-total
                    [sum1]="vm.total.rewards"
                    color1="var(--color-brand-secondary)"
                    i18nLabel1="transactions.label.rewardsTotal"
                    [sum2]="vm.total.fromColleagues"
                    color2="var(--color-additionals-info)"
                    i18nLabel2="transactions.label.fromColleaguesTotal"
                    [i18nTotalTitle]="'transactions.header.incomeTotal'"
                  />
                }
                @case (TransactionGroup.Expense) {
                  <pt-donut-total
                    [sum1]="vm.total.purchases"
                    color1="var(--color-brand-primary)"
                    i18nLabel1="transactions.label.purchasesTotal"
                    [sum2]="vm.total.toColleagues"
                    color2="var(--color-additionals-warning)"
                    i18nLabel2="transactions.label.toColleaguesTotal"
                    i18nTotalTitle="transactions.header.expenseTotal"
                  />
                }
              }
            }
          }

          @if (vm.filters.status !== null) {
            <pt-transactions-total-status
              [status]="vm.filters.status"
              [total]="vm.total"
            />
          }
        </div>
      }
    </div>
  </ng-container>
</ng-container>
