<form
  class="hobby"
  [formGroup]="form"
  (ngSubmit)="applyChanges()"
>
  <ng-container *ngIf="!editMode">
    <div class="hobby__label">
      {{ 'userHobby.hobby' | translate }}:
    </div>
    <div class="hobby__text">
      {{ hobby || '-' }}
    </div>
  </ng-container>

  <ng-container *ngIf="editMode">
    <pu-text-area
      class="pu-form-container"
      *ngIf="editMode"
      [maxLength]="200"
      [rows]="4"
      formControlName="hobby"
      [label]="'userHobby.hobby' | translate"
    />

    <div class="symbols-used">
      {{ 'userHobby.symbols' | translate }} {{ form.controls.hobby.value.length }}/200
    </div>
  </ng-container>

  <div class="actions">
    <button
      class="md:pu-button_m"
      *ngIf="!editMode"
      puButton
      size="l"
      uiType="secondary"
      (click)="editMode = true"
    >
      {{ 'userHobby.editInfo' | translate }}
    </button>

    <button
      class="md:pu-button_m"
      *ngIf="editMode"
      puButton
      size="l"
      uiType="primary"
      type="submit"
    >
      {{ 'userHobby.applyChanges' | translate }}
    </button>

    <button
      class="md:pu-button_m"
      *ngIf="editMode"
      puButton
      size="l"
      uiType="secondary"
      (click)="cancel()"
    >
      {{ 'userHobby.cancel' | translate }}
    </button>
  </div>
</form>
