import { createSelector } from '@ngrx/store';
import { HistoryAchievement } from '@pt/models';

import { achievementsFeature, AchievementsFilters } from './';

const {
  selectFilters,
  selectUserId,
  selectAchievements,
  selectGetAchievementsAction,
} = achievementsFeature;

export interface AchievementsTotal {
  sent: number;
  received: number;
}

export interface AchievementsViewModel {
  filters: AchievementsFilters;
  userId: number;
  achievements: HistoryAchievement[];
  itemsCount: number;
  total: AchievementsTotal;
  isLoading: boolean;
}

const selectViewModel = createSelector(
  selectFilters,
  selectUserId,
  selectAchievements,
  selectGetAchievementsAction,
  (filters, userId, achievements, getAchievementsAction): AchievementsViewModel => ({
    filters,
    userId,
    total: {
      sent: achievements.sentTotal,
      received: achievements.receivedTotal,
    },
    itemsCount: achievements.itemsCount,
    achievements: achievements.userAchievements,
    isLoading: getAchievementsAction.inProgress,
  }),
);

export const AchievementsSelectors = {
  selectFilters,
  selectUserId,
  selectAchievements,
  selectGetAchievementsAction,
  selectViewModel,
};
