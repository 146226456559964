import { ChangeDetectionStrategy, Component, computed, inject, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { UiSelectors } from '@pt/store';
import { AsyncPipe } from '@angular/common';
import { ButtonModule, LetDirective } from '@pinup-teams/common';

@Component({
  standalone: true,
  selector: 'pt-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LetDirective, TranslateModule, RouterLink, AsyncPipe, ButtonModule],
})
export class NotFoundComponent {
  readonly #store = inject(Store);

  isDarkTheme$ = this.#store.select(UiSelectors.selectIsDarkTheme);
}
