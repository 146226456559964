import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DeliveryUkrposhtaForm } from '@pt/models';

@Component({
  selector: 'pu-delivery-view-ukrposhta',
  templateUrl: './ukrposhta.component.html',
  styleUrls: ['./ukrposhta.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UkrposhtaComponent {
  @Input() delivery: DeliveryUkrposhtaForm;
}
