import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { ExpansionPanelModule, IconModule, LetModule } from '@pinup-teams/common';

import { NavItemComponent } from './nav-item.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    IconModule,
    TranslateModule,
    ExpansionPanelModule,
    LetModule,
  ],
  declarations: [NavItemComponent],
  exports: [NavItemComponent],
})
export class NavItemModule {
}
