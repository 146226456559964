import { Data } from '@angular/router';

import { createAction, props } from '@ngrx/store';
import { createAsyncAction } from '@pinup-teams/common';

import { transactionsFeatureKey, TransactionsFilters } from './transactions.state';
import { GetTransactionsReq, GetTransactionsRes } from '../models';

const init = createAction(`[${transactionsFeatureKey}]: Init`, props<{ routeData: Data }>());
const initFilters = createAction(`[${transactionsFeatureKey}]: Init filters`);
const routeChanged = createAction(`[${transactionsFeatureKey}]: Route changed`);
const setFilters = createAction(
  `[${transactionsFeatureKey}]: Set filters`,
  props<{ filters: Partial<TransactionsFilters> }>(),
);
const changeFilters = createAction(
  `[${transactionsFeatureKey}]: Change filters`,
  props<{ filters: Partial<TransactionsFilters> }>(),
);
const setUserId = createAction(
  `[${transactionsFeatureKey}]: Set userId`,
  props<{ userId: number }>(),
);
const getTransactionsReq = createAsyncAction<GetTransactionsReq, GetTransactionsRes>(
  `[${transactionsFeatureKey}]: Get transactions req`,
);

export const TransactionsActions = {
  init,
  initFilters,
  routeChanged,
  setFilters,
  changeFilters,
  filterChangeActions: [changeFilters],
  setUserId,
  getTransactionsReq,
};
