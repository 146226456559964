import { Injectable } from '@angular/core';

import { DialogRef, DialogService } from '@pinup-teams/common';
import { SupportTip } from '@pt/models';

import { TipsComponent } from './tips.component';

@Injectable()
export class TipsDialog {
  constructor(private _dialog: DialogService) {
  }

  open(tips: SupportTip[]): DialogRef {
    return this._dialog.open(TipsComponent, { tips });
  }
}
