import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { atLeastOneValidator, DialogRef, trackByIndex } from '@pinup-teams/common';
import { TeamAward } from '@pt/models';
import { FormArray, FormBuilder } from '@ngneat/reactive-forms';
import { GroupResolverFormBuilder } from '@ngneat/reactive-forms/lib/form-builder';
import { UntilDestroy } from '@ngneat/until-destroy';

export interface TeamMemberAwardsForm {
  awardId: number;
  colleagueId: number;
  comment: string;
}

@UntilDestroy()
@Component({
  selector: 'pu-awards-form',
  templateUrl: './awards-form.component.html',
  styleUrls: ['./awards-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AwardsFormComponent implements OnInit {
  @Input() teamAwards: TeamAward[];

  awardsForm: FormArray<GroupResolverFormBuilder<TeamMemberAwardsForm>>;
  commentsState: Record<string, any> = {};

  trackByIndex = trackByIndex;

  constructor(private _fb: FormBuilder, private _dialogRef: DialogRef) {
  }

  ngOnInit() {
    this.awardsForm = this._initAwardsForm();
  }

  toggleComment(index: number) {
    this.commentsState[index] = !this.commentsState[index];
  }

  send(): void {
    this._dialogRef.close({
      type: 'awards',
      payload: this.awardsForm.value.filter(value => value.awardId),
    });
  }

  close(): void {
    this._dialogRef.close();
  }

  private _initAwardsForm(): FormArray<GroupResolverFormBuilder<TeamMemberAwardsForm>> {
    return new FormArray<GroupResolverFormBuilder<TeamMemberAwardsForm>>(
      this.teamAwards.map(({ colleague }) => this._fb.group<TeamMemberAwardsForm>({
        colleagueId: colleague.id,
        awardId: null,
        comment: '',
      })),
      atLeastOneValidator(control => (<FormArray<TeamMemberAwardsForm>>control).controls.some(
        control => control.value.awardId !== null,
      )),
    );
  }
}
