<pu-avatar
  class="pu-user-profile-card__avatar"
  size="40"
  [userId]="colorfulAvatar ? user.id : undefined"
  [image]="user.imageUrl"
  [nameFirstLetter]="(user.email || user.fullName || user.login || 'A')[0]"
/>

<div class="pu-user-profile-card__container">
  <h3 class="pu-user-profile-card__name">
    {{ user.fullName || user.login }}
  </h3>

  @if (user.position) {
    <p class="pu-user-profile-card__position">
      {{ user.position }}
    </p>
  }

  @if (hasEmail && user.email) {
    <p class="pu-user-profile-card__email">
      {{ user.email }}
    </p>
  }
</div>
