@if (hasNoLimits()) {
  <ng-container *ngTemplateOutlet="noLimitsTpl || defaultNoLimitsTpl"/>
} @else {
  <ng-container *puLet="timerData$ | async as timerData">
    @if (timerData) {
      <ng-container *ngTemplateOutlet="timerTpl || defaultTimerTpl; context: { $implicit: timerData }"/>
    }
  </ng-container>
}

<ng-template
  #defaultTimerTpl
  let-timerData
>
  <div>
    {{ timerData.days | withLeadingZero: withLeadingZero }}
    <sub>{{ 'timer.days' | translate }}</sub>
  </div>

  <span class="pu-timer__divider">:</span>

  <div>
    {{ timerData.hours | withLeadingZero: withLeadingZero }}
    <sub>{{ 'timer.hrs' | translate }}</sub>
  </div>

  <span class="pu-timer__divider">:</span>

  <div>
    {{ timerData.minutes | withLeadingZero: withLeadingZero }}
    <sub>{{ 'timer.min' | translate }}</sub>
  </div>

  <span class="pu-timer__divider">:</span>

  <div>
    {{ timerData.seconds | withLeadingZero: withLeadingZero }}
    <sub>{{ 'timer.sec' | translate }}</sub>
  </div>
</ng-template>

<ng-template #defaultNoLimitsTpl>
  <pu-icon
    iconName="time"
    size="16"
  />
  <span>{{ 'timer.noLimits' | translate }}</span>
</ng-template>
