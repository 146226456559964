import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { environment } from '@pt/environment';
import { delay, Observable, of, switchMap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import {
  createChangeRouteEffect, createRouteFiltersEffect, createAsyncEffect, RouteFiltersSettings,
} from '@pinup-teams/common';
import { RootState } from '@pt/store';
import { getQueryParams } from '@pinup-teams/common';

import { TransactionsFilters } from './transactions.state';
import { TransactionsActions } from './transactions.actions';
import { initialFilters } from './transactions.reducers';
import { TransactionsSelectors } from './transactions.selectors';
import { GetTransactionsReq, GetTransactionsRes } from '../models';
import { getTransactionsMock } from '../mocks';

export const routeFiltersSettings: RouteFiltersSettings<TransactionsFilters> = {
  initialFilters,
  actions: TransactionsActions,
  selectors: TransactionsSelectors,
  queryParams: ['page', 'limit', 'period', 'group:string', 'status:string'],
};

@Injectable()
export class TransactionsEffects {
  private readonly _actions$ = inject(Actions);
  private readonly _store = inject(Store<RootState>);
  private readonly _router = inject(Router);
  private readonly _http = inject(HttpClient);

  forTeam: boolean;

  readonly init$ = createEffect(() => this._actions$.pipe(
    ofType(TransactionsActions.init),
    switchMap(action => {
      this.forTeam = action.routeData['forTeam'];

      return [
        TransactionsActions.setUserId({ userId: action.routeData['userId'] }),
        TransactionsActions.initFilters(),
      ];
    }),
  ));
  readonly routeFilters$ = createRouteFiltersEffect<TransactionsFilters>(
    <any> this,
    routeFiltersSettings,
  );
  readonly changeRoute$ = createChangeRouteEffect<TransactionsFilters>(
    <any> this,
    routeFiltersSettings,
  );

  readonly changeFilters$ = createEffect(() => this._actions$.pipe(
    ofType(TransactionsActions.changeFilters),
    concatLatestFrom(() => [this._store.select(TransactionsSelectors.selectFilters)]),
    switchMap(([, filters]) => [
      TransactionsActions.getTransactionsReq.action({ payload: filters }),
    ]),
  ));

  readonly getTransactionsReq$ = createEffect(() => this._actions$.pipe(
    ofType(TransactionsActions.getTransactionsReq.action),
    concatLatestFrom(() => [this._store.select(TransactionsSelectors.selectUserId)]),
    switchMap(([{ payload }, userId]) => createAsyncEffect(
      this.getTransactions(payload, userId),
      TransactionsActions.getTransactionsReq,
    )),
  ));

  getTransactions(params: GetTransactionsReq, userId?: number): Observable<GetTransactionsRes> {
    if (environment.useMocks) {
      const transactions = getTransactionsMock(24, this.forTeam);

      return of({
        rewardTotal: 3000,
        funcRewardTotal: 2000,
        fromColleagueTotal: 1000,
        refundTotal: 700,
        purchaseTotal: 2000,
        toColleagueTotal: 500,
        rollbackTotal: 800,
        sentTotal: 2000,
        receivedTotal: 5000,
        burnedTotal: 3000,
        itemsCount: transactions.length,
        transactions,
      }).pipe(delay(500));
    } else {
      const api
        = environment.apiHost
        + (this.forTeam
          ? userId
            ? `team-reward/admin/user-team-bonus-histories/${userId}/`
            : 'team-reward/history/'
          : userId
            ? `banking/${userId}/transactions/`
            : 'banking/transactions/');

      return this._http.get<GetTransactionsRes>(api, { params: getQueryParams(params) });
    }
  }
}
