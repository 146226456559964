import { createSelector } from '@ngrx/store';
import { Transaction } from '@pt/models';
import { UiSelectors } from '@pt/store';

import { TransactionsFilters } from './transactions.state';
import { transactionsFeature } from './transactions.reducers';

const {
  selectFilters,
  selectUserId,
  selectTransactions,
  selectGetTransactionsAction,
} = transactionsFeature;

export interface TransactionsTotal {
  rewards: number;
  funcRewards: number;
  fromColleagues: number;
  refunds: number;
  purchases: number;
  toColleagues: number;
  rollback: number;
  sent: number;
  received: number;
  burned: number;
}

export interface TransactionsViewModel {
  filters: TransactionsFilters;
  userId: number;
  transactions: Transaction[];
  itemsCount: number;
  total: TransactionsTotal;
  isLoading: boolean;
  isShadowVisible: boolean;
  isNavbarHidden: boolean;
}

const selectViewModel = createSelector(
  selectFilters,
  selectUserId,
  selectTransactions,
  selectGetTransactionsAction,
  UiSelectors.selectIsShadowVisibleByScroll,
  UiSelectors.selectIsNavbarHiddenByScroll,
  (
    filters,
    userId,
    transactions,
    getTransactionsAction,
    isShadowVisibleByScroll,
    isNavbarHidden,
  ): TransactionsViewModel => ({
    filters,
    userId,
    total: {
      rewards: transactions.rewardTotal,
      funcRewards: transactions.funcRewardTotal,
      fromColleagues: transactions.fromColleagueTotal,
      refunds: transactions.refundTotal,
      purchases: transactions.purchaseTotal,
      toColleagues: transactions.toColleagueTotal,
      rollback: transactions.rollbackTotal,
      sent: transactions.sentTotal,
      received: transactions.receivedTotal,
      burned: transactions.burnedTotal,
    },
    itemsCount: transactions.itemsCount,
    transactions: transactions.transactions,
    isLoading: getTransactionsAction.inProgress,
    isShadowVisible: isShadowVisibleByScroll,
    isNavbarHidden,
  }),
);

export const TransactionsSelectors = {
  selectFilters,
  selectUserId,
  selectTransactions,
  selectGetTransactionsAction,
  selectViewModel,
};
