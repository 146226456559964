<div class="additional-info">
  <div
    class="card-item"
    style="grid-area: phone-number"
  >
    <div class="card-item__label">
      {{ 'userAdditionalInfo.phone' | translate }}:
    </div>
    <div class="card-item__text">
      {{ additionalInfo.phone }}
    </div>
  </div>
  <div
    class="card-item"
    style="grid-area: start-date"
  >
    <div class="card-item__label">
      {{ 'userAdditionalInfo.startDate' | translate }}:
    </div>
    <div class="card-item__text">
      {{ (additionalInfo.startDate | date : 'dd/MMM/YY') || '-' }}
    </div>
  </div>
  <div
    class="card-item"
    style="grid-area: hobby"
  >
    <div class="card-item__label">
      {{ 'userAdditionalInfo.hobby' | translate }}:
    </div>
    <div class="card-item__text">
      {{ additionalInfo.hobby || '-' }}
    </div>
  </div>
</div>
