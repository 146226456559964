import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { TransactionsComponent } from './transactions.component';
import { transactionsFeature, TransactionsEffects } from './store';

const routes: Routes = [
  {
    path: '',
    component: TransactionsComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    StoreModule.forFeature(transactionsFeature),
    EffectsModule.forFeature([TransactionsEffects]),
    TransactionsComponent,
  ],
  exports: [TransactionsComponent],
})
export class TransactionsModule {
}
