<ng-container *puSpinner="isLoading">
  <div class="pu-support-contact__header">
    <h4
      class="pu-support-contact__header-text"
      translate="support.contactDialog.header"
    >
    </h4>

    <button
      class="pu-support-contact__close"
      type="button"
      puButton
      size="m"
      uiType="quaternary"
      [onlyIcon]="true"
      (click)="cancel()"
    >
      <pu-icon
        iconName="close"
        size="16"
      />
    </button>
  </div>

  <form
    class="pu-support-contact__body"
    [formGroup]="form"
    (ngSubmit)="send()"
  >

    <img
      class="pu-support-contact__img"
      [src]="assetsHost+'/assets/images/chat.png'"
      alt="Contact support icon"
    >

    <p
      class="pu-support-contact__text"
      [innerHTML]="'support.contactDialog.text' | translate"
    >
    </p>

    <pu-text-area
      class="pu-form-container"
      formControlName="text"
      [label]="'support.contactDialog.control.question' | translate"
    />

    <pu-uploader
      class="pu-form-container"
      formControlName="images"
      apiS3="upload/image/"
      accept=".jpg,.jpeg,.png"
      labelKey="support.contactDialog.control.images.label"
      placeholderKey="support.contactDialog.control.images.placeholder"
      multiple
      hiddenPlaceholder
    />

    <div class="pu-support-contact__actions">
      <button
        type="submit"
        puButton
        size="l"
        uiType="primary"
        [disabled]="!form.valid"
        translate="support.contactDialog.btn.send"
      >
      </button>

      <button
        type="button"
        puButton
        size="l"
        uiType="secondary"
        (click)="cancel()"
        translate="support.contactDialog.btn.cancel"
      >
      </button>
    </div>
  </form>
</ng-container>
