<a
  class="pt-user-action-card__link"
  [routerLink]="['/colleague', user().id]"
>
  <pu-user-profile-card [user]="user()"/>
</a>
<button
  class="pt-user-action-card__btn"
  puButton
  uiType="quaternary"
  [disabled]="isActionDisabled()"
  [onlyIcon]="!btnLabel()"
  (click)="btnClick.emit()"
>
  <pu-icon [iconName]="iconName()"/>

  @if (btnLabel(); as label) {
    <span class="pt-user-action-card__btn__label">
      {{ label | translate }}
    </span>
  }
</button>
