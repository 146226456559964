import {
  ChangeDetectionStrategy, Component, computed, ElementRef, input, OnChanges, OnInit, SimpleChanges,
  viewChild,
} from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { LineChart } from './line-chart';
import { LineChartData, LineChartLegend, LineChartViewConfig, LineConfig } from './models';

@Component({
  selector: 'pt-line-chart',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineChartComponent implements OnChanges, OnInit {
  config = input<LineChartViewConfig>();
  data = input.required<LineChartData[][]>();

  legends = computed<LineChartLegend[]>(() => this.config().legends);

  private _chart = new LineChart();
  private _chartRef = viewChild<ElementRef>('chart');
  private _chartElement = computed<HTMLElement>(() => this._chartRef().nativeElement);

  ngOnChanges({ data, config }: SimpleChanges): void {
    const dataValue = data?.currentValue;
    const configValue = config?.currentValue;

    if (dataValue && this._chart.svg) {
      this._updateChart(dataValue);
    }

    if (configValue) {
      const svg = this._chart.handleConfigChange(configValue);

      if (svg) {
        this._chartElement().replaceChild(svg, this._chartElement().firstChild);
      }
    }
  }

  ngOnInit(): void {
    this._createChart();
  }

  private _createChart(): void {
    this._chart.init();
    this._chartElement().appendChild(this._chart.render());
  }

  private _updateChart(chartData: LineChartData[][]): void {
    const elementSVG = this._chart.handleDataChange(this._generateChartLinesData(chartData));

    this._chartElement().replaceChild(elementSVG, this._chartElement().firstChild);
  }

  private _generateChartLinesData(data: LineChartData[][]): LineConfig[] {
    return data.map(
      (collection, index): LineConfig => ({
        data: collection.map((i: LineChartData) => ({
          xValue: new Date(i.date).getDate(),
          yValue: i.amount,
        })),
        color: this.legends()[index].color,
      }),
    );
  }
}
