import { LineChartConfig } from './models';

export const defaultConfig: LineChartConfig = {
  height: 300,
  legendText: null,
  legendTextPositionY: 12,
  lineWidth: 1.5,
  marginBottom: 18,
  marginLeft: 24,
  marginRight: 8,
  marginTop: 8,
  numberOfTicksX: 5,
  numberOfTicksY: 6,
  width: 400,
};
