import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DialogService } from '@pinup-teams/common';
import { environment } from '@pt/environment';
import { switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthSelectors } from '@pt/auth';
import { RootState } from '@pt/store';

import { AchievementDialogComponent } from './achievement-dialog.component';
import { getAchievementMock } from './mocks';
import { GetAchievementRes } from './models';

@Injectable({
  providedIn: 'root',
})
export class AchievementDialog {
  profile$ = this._store.select(AuthSelectors.selectProfile);

  constructor(
    private _dialog: DialogService,
    private _http: HttpClient,
    private _store: Store<RootState>,
  ) {
  }

  open(achievementId: number, colleagueId: number = null): void {
    const getAchievement$ = environment.useMocks
      ? getAchievementMock(achievementId)
      : this.profile$.pipe(
        switchMap(({ id }) => {
          const userId = colleagueId || id;

          return this._http.get<GetAchievementRes>(
            environment.apiHost
            + `achievements/colleague/${userId}/achievement/${achievementId}/`,
          );
        }),
      );

    getAchievement$
      .pipe(switchMap(({ achievement }) => this._dialog.open(
        AchievementDialogComponent,
        { achievement },
      ).afterClosed()))
      .subscribe();
  }
}
