import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DeliveryAcsForm } from '@pt/models';

@Component({
  selector: 'pu-delivery-view-acs',
  templateUrl: './acs.component.html',
  styleUrls: ['./acs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcsComponent {
  @Input() delivery: DeliveryAcsForm;
}
