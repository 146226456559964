import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '@pinup-teams/common';
import { Managers } from '@pt/models';
import { AvatarModule } from '@pt/components';

import { UserInfoCardComponent } from '../user-info-card/user-info-card.component';

@Component({
  selector: 'pu-user-managers',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    UserInfoCardComponent,
    ButtonModule,
    AvatarModule,
    RouterLink,
  ],
  templateUrl: './user-managers.component.html',
  styleUrls: ['./user-managers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserManagersComponent {
  @Input() managers: Managers;
}
