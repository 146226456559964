<div class="managers">
  <div
    class="card-item"
    *ngIf="managers.directManager"
  >
    <div class="card-item__label">
      {{ 'userManagers.directManager' | translate }}
    </div>
    <a
      class="manager-item"
      [routerLink]="['/colleague', managers.directManager.id]"
    >
      <pu-avatar
        size="40"
        [image]="managers.directManager.picture"
        [nameFirstLetter]="managers.directManager.email && managers.directManager.email[0]"
      />
      <div class="manager-item__name">
        {{ managers.directManager.fullName }}
      </div>
      <div class="manager-item__position">
        {{ managers.directManager.position }}
      </div>
    </a>
  </div>
  <div
    class="card-item"
    *ngIf="managers.functionalManager"
  >
    <div class="card-item__label">
      {{ 'userManagers.functionalManager' | translate }}
    </div>
    <a
      class="manager-item"
      [routerLink]="['/colleague', managers.functionalManager.id]"
    >
      <pu-avatar
        size="40"
        [image]="managers.functionalManager.picture"
        [nameFirstLetter]="managers.functionalManager.email && managers.functionalManager.email[0]"
      />
      <div class="manager-item__name">
        {{ managers.functionalManager.fullName }}
      </div>
      <div class="manager-item__position">
        {{ managers.functionalManager.position }}
      </div>
    </a>
  </div>
  <div
    class="card-item"
    *ngIf="managers.peoplePartner"
  >
    <div class="card-item__label">
      {{ 'userManagers.peoplePartner' | translate }}
    </div>
    <a
      class="manager-item"
      [routerLink]="['/colleague', managers.peoplePartner.id]"
    >
      <pu-avatar
        size="40"
        [image]="managers.peoplePartner.picture"
        [nameFirstLetter]="managers.peoplePartner.email && managers.peoplePartner.email[0]"
      />
      <div class="manager-item__name">
        {{ managers.peoplePartner.fullName }}
      </div>
      <div class="manager-item__position">
        {{ managers.peoplePartner.position }}
      </div>
    </a>
  </div>
</div>
