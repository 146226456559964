<svg
  [style]="styles"
  [attr.height]="SIZE"
  [attr.width]="SIZE"
  [attr.viewBox]="'0 0 ' + SIZE + ' ' + SIZE"
>
  <defs>
    <linearGradient
      class="gradient"
      [id]="'gradient' + status"
    >
      <stop offset="0%"/>
      <stop offset="100%"/>
    </linearGradient>
  </defs>

  <image
    [attr.href]="image.url"
    [attr.x]="barWidth * 2"
    [attr.y]="barWidth * 2"
    [attr.height]="SIZE - barWidth * 4"
    [attr.width]="SIZE - barWidth * 4"
    preserveAspectRatio="xMidYMid slice"
    clip-path="inset(0% round 50%)"
  />

  <g
    [attr.stroke-width]="barWidth"
    fill="none"
  >
    <circle
      class="pu-circle-progress__track-circle"
      [attr.cx]="SIZE / 2"
      [attr.cy]="SIZE / 2"
      [attr.r]="radius"
    />
    <circle
      [attr.cx]="SIZE / 2"
      [attr.cy]="SIZE / 2"
      [attr.r]="radius"
      stroke-linecap="round"
      [attr.stroke]="'url(#gradient' + status + ')'"
      [attr.transform]="'rotate(-90,' + SIZE / 2 + ',' + SIZE / 2 + ')'"
      [attr.stroke-dasharray]="2 * PI * radius"
      [attr.stroke-dashoffset]="2 * PI * radius * ((100 - config[status].progress) / 100)"
    />
  </g>
</svg>
