import { Motion } from "./Options/Classes/Motion.js";
import { MotionInstance } from "./MotionInstance.js";
export class MotionPlugin {
  constructor(engine) {
    this.id = "motion";
    this._engine = engine;
  }
  getPlugin(container) {
    return Promise.resolve(new MotionInstance(container, this._engine));
  }
  loadOptions(options, source) {
    if (!this.needsPlugin()) {
      return;
    }
    let motionOptions = options.motion;
    if (!motionOptions?.load) {
      options.motion = motionOptions = new Motion();
    }
    motionOptions.load(source?.motion);
  }
  needsPlugin() {
    return true;
  }
}