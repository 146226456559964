import { inject, Injectable } from '@angular/core';

import { DialogRef, DialogService } from '@pinup-teams/common';
import { User } from '@pt/models';

import { TransactionsDialogComponent } from './transactions-dialog.component';

@Injectable()
export class TransactionsDialog {
  private readonly _dialog = inject(DialogService);

  open(user: User, isAdmin = true, forTeam = false): DialogRef {
    return this._dialog.open(TransactionsDialogComponent, { user, forTeam, isAdmin });
  }
}
