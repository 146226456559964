import { Observable, delay, of } from 'rxjs';
import { fillArr } from '@pinup-teams/common';
import { environment } from '@pt/environment';

import { GetBalanceRes, GetBalanceSkinsRes, GetTeamBalanceRes } from '../models';

const getSkinMock = (i: number) => {
  return {
    id: i,
    url: `${environment.assetsHost}/assets/images/balance/balance-card-mock${i % 2 === 0
      ? '-dark'
      : ''}.png`,
    name: `${i}.jpg`,
    isDark: i % 2 === 0,
  };
};

export const getBalanceSkinsMock = (count = 12): Observable<GetBalanceSkinsRes> => {
  return of({
    skins: fillArr(count, i => getSkinMock(i)),
  }).pipe(delay(500));
};

export const getBalanceMock = (): Observable<GetBalanceRes> => {
  return of({
    balanceType: 'regular' as const,
    amount: 9999,
    skin: getSkinMock(0),
  }).pipe(delay(500));
};

export const getTeamBalanceMock = (): Observable<GetTeamBalanceRes> => {
  return of({
    teamBalance: {
      balanceType: 'functional' as const,
      amount: 120,
      skin: getSkinMock(1),
    },
  }).pipe(delay(500));
};
