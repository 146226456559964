import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { PersonalInfoMainInfo } from '@pt/models';

import { UserInfoCardComponent } from '../user-info-card/user-info-card.component';

@Component({
  selector: 'pu-user-main-info',
  standalone: true,
  imports: [CommonModule, TranslateModule, UserInfoCardComponent],
  templateUrl: './user-main-info.component.html',
  styleUrls: ['./user-main-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMainInfoComponent {
  @Input() mainInfo: PersonalInfoMainInfo;
}
