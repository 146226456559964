<div class="pu-achievement-dialog__header">
  <h4 class="pu-achievement-dialog__header-text">
    {{ achievement.name }}
  </h4>

  <button
    class="pu-achievement-dialog__close"
    type="button"
    puButton
    size="m"
    uiType="quaternary"
    [onlyIcon]="true"
    (click)="close()"
  >
    <pu-icon
      iconName="close"
      size="16"
    />
  </button>
</div>

<div class="pu-achievement-dialog__body">
  <pt-achievement
    class="pu-achievement-dialog__achievement"
    [achievement]="achievement"
  />

  @if (achievement.description) {
    <div
      class="pu-achievement-dialog__description"
      [froalaView]="achievement.description"
    ></div>
  }

  @if (hasRewardedFor) {
    <div class="pu-achievement-dialog__rewarded_for">
      <h3 class="pu-achievement-dialog__rewarded_for-text">
        {{ 'achievementDialog.rewardedFor' | translate }}:
      </h3>

      <div class="pu-achievement-dialog__rewarded_for-list">
        @for (entity of achievement.rewardedBy; track entity) {
          @if (entity.rewardedFor) {
            <span class="pu-achievement-dialog__rewarded_for-item-text">
              <ng-container *ngTemplateOutlet="achievementTmpl; context: { $implicit: entity.rewardedFor, rewardedCount: entity.rewardedCount }"/>
            </span>
          }
        }
      </div>
    </div>
  }

  @if (hasRewardedBy) {
    <div class="pu-achievement-dialog__users">
      <h3 class="pu-achievement-dialog__users-text">
        {{ 'achievementDialog.rewardedBy' | translate }}:
      </h3>

      <div class="pu-achievement-dialog__users-list">
        @for (entity of achievement.rewardedBy; track entity) {
          @if (entity.user) {
            <a
              class="pu-achievement-dialog__users-item"
              [routerLink]="['/colleague', entity.user.id]"
              (click)="close()"
            >
              <ng-container *ngTemplateOutlet="achievementTmpl; context: { $implicit: entity.user.fullName, rewardedCount: entity.rewardedCount }"/>
            </a>
          } @else if (entity.course) {
            <span>
              <ng-container *ngTemplateOutlet="achievementTmpl; context: { $implicit: entity.course.name, rewardedCount: entity.rewardedCount }"/>
            </span>
          } @else if (entity.onboardingStage) {
            <span>
              <ng-container *ngTemplateOutlet="achievementTmpl; context: { $implicit: entity.onboardingStage.name, rewardedCount: entity.rewardedCount }"/>
            </span>
          }
        }
      </div>
    </div>
  }
</div>

<ng-template
  #achievementTmpl
  let-name
  let-rewardedCount="rewardedCount"
>
  {{ name }}
  @if (rewardedCount >= 2) {
    <span>
      (x{{ rewardedCount }})
    </span>
  }
</ng-template>
