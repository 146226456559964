import {
  ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Order, OrderAdminForm } from '@pt/models';
import { DictionariesService } from '@pt/services';

type DeliveryType = 'site' | 'admin';

@Component({
  selector: 'pu-delivery-view',
  templateUrl: './delivery-view.component.html',
  styleUrls: ['./delivery-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
})
export class DeliveryViewComponent implements OnInit {
  @Input() order: Order | OrderAdminForm;
  /**
   * Type of view
   */
  @Input() type: DeliveryType = 'admin';

  delivery: any;
  deliveryMethodName: string;

  constructor(private _translate: TranslateService, private _dictionaries: DictionariesService) {
  }

  ngOnInit() {
    this.delivery = this.order.delivery;

    const deliveries = this._dictionaries.deliveries;

    this.deliveryMethodName
      = this.order.deliveryMethod === 'pickup'
        ? this._translate.instant('delivery.pickup')
        : deliveries.find(item => item.id === this.order.deliveryMethod).name;
  }
}
