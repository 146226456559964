import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  effect,
  ElementRef,
  inject,
  input,
  NgZone,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { confetti } from '@tsparticles/confetti';
import { merge, ReplaySubject, switchMap, take, tap, timer } from 'rxjs';
import { IConfettiOptions } from '@tsparticles/confetti/types/IConfettiOptions';
import type { RecursivePartial } from '@tsparticles/engine';
import { uniqueId } from '@pinup-teams/common';

export type ConfettiOptions = RecursivePartial<IConfettiOptions>;
export type ConfettiOptionsWithTimer = ConfettiOptions & {
  startDue: number;
  intervalDuration: number;
  shootTimes: number;
};

/**
 * https://particles.js.org/docs/modules/tsParticles_Confetti_Bundle.html#md:options
 */
const CONFETTI_DEFAULT_COMMON_OPTIONS: ConfettiOptions = {
  count: 50,
  spread: 45,
  startVelocity: 100,
  decay: 0.9,
  gravity: 1,
  drift: 0,
  ticks: 100,
  colors: ['#ff2400', '#00cfa6', '#fac600', '#2c99ff', '#ad00ff'],
  shapes: ['square', 'circle', 'star', 'polygon'],
  scalar: 1,
  zIndex: 100,
  disableForReducedMotion: false,
};
const CONFETTI_DEFAULT_OPTIONS: ConfettiOptionsWithTimer[] = [
  {
    angle: 45,
    position: {
      x: 0,
      y: 100,
    },
    startDue: 0,
    intervalDuration: 1000,
    shootTimes: 3,
  },
  {
    angle: 135,
    position: {
      x: 100,
      y: 100,
    },
    startDue: 400,
    intervalDuration: 1000,
    shootTimes: 3,
  },
];

@Component({
  selector: 'pt-confetti',
  template: '<canvas [id]="id" #canvas></canvas>',
  styles: `
    :host {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    canvas {
      width: 100%;
      height: 100%;
    }
  `,
  standalone: true,
  exportAs: 'confetti',
})
export class ConfettiComponent implements AfterViewChecked {
  private _destroyRef = inject(DestroyRef);
  private _cdr = inject(ChangeDetectorRef);
  private _zone = inject(NgZone);

  commonOptions = input<ConfettiOptions>(CONFETTI_DEFAULT_COMMON_OPTIONS);
  confettiOptions = input<ConfettiOptionsWithTimer[]>(CONFETTI_DEFAULT_OPTIONS);
  canvasElementRef = viewChild.required<ElementRef<HTMLCanvasElement>>('canvas');
  canvas = computed(() => this.canvasElementRef().nativeElement);
  declareConfettiFnEffect = effect(async () => {
    this.confettiFn = await confetti.create(this.canvas(), {});
    this.initialized$.next();
  });

  id = uniqueId();
  confettiFn: Awaited<ReturnType<typeof confetti.create>>;
  initialized$ = new ReplaySubject<void>(1);

  ngAfterViewChecked(): void {
    this._cdr.detach();
  }

  shootConfetti(): void {
    this.initialized$
      .pipe(
        switchMap(() => merge(
          ...this.confettiOptions().map(options => timer(options.startDue, options.intervalDuration)
            .pipe(
              take(options.shootTimes),
              tap(() => this._zone.runOutsideAngular(() => this.confettiFn({
                ...this.commonOptions(), ...options,
              }))),
            )),
        )),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe();
  }
}
