import {
  booleanAttribute, ChangeDetectionStrategy, Component, ContentChild, HostBinding, inject, Input,
  TemplateRef,
} from '@angular/core';
import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';

import {
  IconModule, LetModule, OneSecondIntervalService, WithLeadingZeroPipe,
} from '@pinup-teams/common';
import {
  combineLatest, distinctUntilChanged, filter, map, Observable, ReplaySubject, share, switchMap,
  takeWhile,
} from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { TimeDeltaService } from '@pt/services';

interface DurationData {
  startedAt: string | null;
  expiredAt: string | null;
}

interface TimerData {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  /**
   * Amount of seconds between startedAt and expiredAt
   */
  secondsTotal: number;
  /**
   * Amount of seconds between now and expiredAt
   */
  secondsLeftTotal: number;
}

const defaultValue: TimerData = {
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
  secondsTotal: 0,
  secondsLeftTotal: 0,
};

@Component({
  standalone: true,
  selector: 'pu-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
  imports: [
    AsyncPipe,
    LetModule,
    NgIf,
    WithLeadingZeroPipe,
    NgTemplateOutlet,
    IconModule,
    TranslateModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'timer',
})
export class TimerComponent {
  private _interval$ = inject(OneSecondIntervalService);
  private _timeDeltaSubject = inject(TimeDeltaService);

  @Input() set durationData(data: DurationData) {
    this.durationData$.next(data);
  }

  @Input({ transform: booleanAttribute }) withLeadingZero = false;
  @Input({ transform: booleanAttribute }) addExtraDay = false;

  @ContentChild('timerTpl') timerTpl: TemplateRef<any>;
  @ContentChild('noLimitsTpl') noLimitsTpl: TemplateRef<any>;

  @HostBinding('class.pu-timer_expired') get applyExpiredClass() {
    return this.isExpired();
  }

  @HostBinding('class.pu-timer_no-limits') get applyNoLimitsClass() {
    return this.hasNoLimits();
  }

  durationData$ = new ReplaySubject<DurationData>(1);
  startedAtInMsec$ = this.durationData$.pipe(
    map(({ startedAt }) => {
      const now = Date.now() + this._timeDeltaSubject.value;

      return Date.parse(startedAt) || now;
    }),
  );
  expiredAtInMsec$ = this.durationData$.pipe(
    map(({ expiredAt }) => {
      const oneDayInMsec = 24 * 60 * 60 * 1000;
      const parsedExpiredAt = Date.parse(expiredAt) || null;

      if (this.addExtraDay && parsedExpiredAt) {
        return parsedExpiredAt + oneDayInMsec;
      } else if (parsedExpiredAt) {
        return parsedExpiredAt;
      }

      return null;
    }),
  );
  timerData$: Observable<TimerData> = combineLatest([
    this.startedAtInMsec$,
    this.expiredAtInMsec$.pipe(filter(Boolean)),
  ]).pipe(
    switchMap(([startedAtInMsec, expiredAtInMsec]) => this._interval$.pipe(map(() => this._count(
      expiredAtInMsec,
      startedAtInMsec,
    )))),
    takeWhile(timerData => timerData.secondsLeftTotal > 0, true),
    share(),
  );

  isExpired = toSignal(
    this.timerData$.pipe(
      map(timerData => timerData.secondsLeftTotal === 0),
      distinctUntilChanged(),
    ),
    { initialValue: false },
  );
  hasNoLimits = toSignal(
    this.expiredAtInMsec$.pipe(map(expireAt => !expireAt)),
    { initialValue: false },
  );

  private _count(expiredAtInMsec: number, startedAtInMsec: number): TimerData {
    const now = Date.now() + this._timeDeltaSubject.value;
    const delta = expiredAtInMsec - now;
    const expiredStartedDelta = expiredAtInMsec - startedAtInMsec;
    const secondsTotal = expiredStartedDelta ? Math.floor(expiredStartedDelta / 1000) : 0;

    if (delta <= 0) {
      return { ...defaultValue, secondsTotal };
    }

    const days = Math.floor(delta / 1000 / 60 / 60 / 24);
    const hours = Math.floor((delta - (days * 24 * 60 * 60 * 1000)) / 1000 / 60 / 60);
    const minutes = Math.floor(
      (delta - (days * 24 * 60 * 60 * 1000) - (hours * 60 * 60 * 1000)) / 1000 / 60,
    );
    const seconds = Math.floor((delta - (days * 24 * 60 * 60 * 1000) - (hours * 60 * 60 * 1000)
      - (minutes * 60 * 1000)) / 1000);
    const secondsLeftTotal = Math.floor(delta > 0 ? delta / 1000 : 0);

    return {
      days,
      hours,
      minutes,
      seconds,
      secondsTotal,
      secondsLeftTotal,
    };
  }
}
