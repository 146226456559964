import { createSelector } from '@ngrx/store';
import { TeamAward, TeamBonus } from '@pt/models';

import { teamBonusesFeature } from './team-bonuses.reducers';

const {
  selectTeamAwards,
  selectTeamBonus,
  selectGetTeamAwardsAction,
  selectGetTeamBonusAction,
  selectSendAwardAction,
  selectSendBonusAction,
} = teamBonusesFeature;

export interface TeamBonusesViewModel {
  teamAwards: TeamAward[];
  teamBonus: TeamBonus;
}

const selectViewModel = createSelector(
  selectTeamAwards,
  selectTeamBonus,
  (teamAwards, teamBonus): TeamBonusesViewModel => ({
    teamBonus,
    teamAwards,
  }),
);

const selectIsLoading = createSelector(
  selectGetTeamAwardsAction,
  selectGetTeamBonusAction,
  selectSendAwardAction,
  selectSendBonusAction,
  (
    getTeamAwardsAction,
    getTeamBonusAction,
    sendAwardAction,
    sendBonusAction,
  ): boolean => getTeamAwardsAction.inProgress || getTeamBonusAction.inProgress
    || sendAwardAction.inProgress
    || sendBonusAction.inProgress,
);

export const TeamBonusesSelectors = {
  selectTeamBonus,
  selectTeamAwards,
  selectSendAwardAction,
  selectSendBonusAction,
  selectIsLoading,
  selectViewModel,
};
