import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconModule } from '@pinup-teams/common';

import { AvatarComponent } from './avatar.component';
import { ImageLoaderDirective } from '@pt/directives';

@NgModule({
  declarations: [AvatarComponent],
  imports: [CommonModule, IconModule, ImageLoaderDirective],
  exports: [AvatarComponent],
})
export class AvatarModule {
}
