import { AsyncState } from '@pinup-teams/common';

import { ApplyBalanceSkinRes, GetTagsRes, GetBalanceRes, GetTeamBalanceRes } from '../models';

export const balanceFeatureKey = 'Balance';

export interface BalanceState {
  balance: GetBalanceRes;
  teamBalance: GetTeamBalanceRes['teamBalance'];
  tags: GetTagsRes;
  getBalanceAction: AsyncState<GetBalanceRes>;
  getTeamBalanceAction: AsyncState<GetTeamBalanceRes>;
  getBalanceSkinsAction: AsyncState<GetBalanceRes>;
  applyBalanceSkinAction: AsyncState<ApplyBalanceSkinRes>;
  getTagsAction: AsyncState<GetTagsRes>;
}
