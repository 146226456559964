import { createAction } from '@ngrx/store';
import { createAsyncAction, createAsyncActionNoProps } from '@pinup-teams/common';

import { teamBonusesFeatureKey } from './team-bonuses.state';
import {
  GetTeamAwardsRes, GetTeamBonusRes, SendAwardReq, SendAwardRes, SendBonusReq, SendBonusRes,
} from '../models';

const init = createAction(`[${teamBonusesFeatureKey}]: Init`);
const openTeamBonusesDialog = createAction(`[${teamBonusesFeatureKey}]: Open team bonuses dialog`);
const getTeamAwardsReq = createAsyncActionNoProps<GetTeamAwardsRes>(
  `[${teamBonusesFeatureKey}]: Get team awards`,
);
const getTeamBonusReq = createAsyncActionNoProps<GetTeamBonusRes>(
  `[${teamBonusesFeatureKey}]: Get team bonus`,
);
const sendAwardReq = createAsyncAction<SendAwardReq, SendAwardRes>(
  `[${teamBonusesFeatureKey}]: Send award req`,
);
const sendBonusReq = createAsyncAction<SendBonusReq, SendBonusRes>(
  `[${teamBonusesFeatureKey}]: Send bonus req`,
);

export const TeamBonusesActions = {
  init,
  openTeamBonusesDialog,
  getTeamAwardsReq,
  getTeamBonusReq,
  sendAwardReq,
  sendBonusReq,
};
