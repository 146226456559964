import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { delay, map, Observable, of, switchMap } from 'rxjs';
import { RootState } from '@pt/store';
import { environment } from '@pt/environment';
import { Store } from '@ngrx/store';
import { createAsyncEffect, noopAction } from '@pinup-teams/common';

import { BalanceActions } from './balances.actions';
import {
  ApplyBalanceSkinReq, GetTagsRes, GetBalanceSkinsRes, GetBalanceRes, GetTeamBalanceRes,
} from '../models';
import { BalanceSkinFormDialog } from '../components/balance-skin-form/balance-skin-form.dialog';
import { getBalanceSkinsMock, getBalanceMock, getTeamBalanceMock, getTagsMock } from '../mocks';

@Injectable()
export class BalanceEffects {
  init$ = createEffect(() => this._actions$.pipe(
    ofType(BalanceActions.init),
    switchMap(() => [
      BalanceActions.getBalanceReq.action(),
      BalanceActions.getTagsReq.action(),
      BalanceActions.getTeamBalanceReq.action(),
    ]),
  ));

  getBalanceReq$ = createEffect(() => this._actions$.pipe(
    ofType(BalanceActions.getBalanceReq.action),
    switchMap(() => createAsyncEffect(this.getBalance(), BalanceActions.getBalanceReq)),
  ));

  getTeamBalanceReq$ = createEffect(() => this._actions$.pipe(
    ofType(BalanceActions.getTeamBalanceReq.action),
    switchMap(() => createAsyncEffect(this.getTeamBalance(), BalanceActions.getTeamBalanceReq)),
  ));

  getBalanceSkinsReq$ = createEffect(() => this._actions$.pipe(
    ofType(BalanceActions.getBalanceSkinsReq.action),
    switchMap(({ initiator }) => createAsyncEffect(
      this.getBalanceSkins(),
      BalanceActions.getBalanceSkinsReq,
      initiator,
    )),
  ));

  getTagsReq$ = createEffect(() => this._actions$.pipe(
    ofType(BalanceActions.getTagsReq.action),
    switchMap(() => createAsyncEffect(this.getTags(), BalanceActions.getTagsReq)),
  ));

  openBalanceSkinsDialog$ = createEffect(() => this._actions$.pipe(
    ofType(BalanceActions.getBalanceSkinsReq.succeededAction),
    switchMap(({ payload, initiator }) => {
      const dialogRef = this._balanceSkinsDialog.open(payload.skins, initiator);

      return dialogRef.afterClosed().pipe(
        map(result => {
          return result
            ? BalanceActions.applySkinReq.action({ payload: <ApplyBalanceSkinReq>result })
            : noopAction();
        }),
      );
    }),
  ));

  applySkin$ = createEffect(() => this._actions$.pipe(
    ofType(BalanceActions.applySkinReq.action),
    switchMap(({ payload }) => createAsyncEffect(
      this.applySkin(payload),
      BalanceActions.applySkinReq,
    )),
  ));

  updateBalance$ = createEffect(() => this._actions$.pipe(
    ofType(BalanceActions.applySkinReq.succeededAction),
    switchMap(() => [
      BalanceActions.getBalanceReq.action(),
      BalanceActions.getTeamBalanceReq.action(),
    ]),
  ));

  constructor(
    private _actions$: Actions,
    private _http: HttpClient,
    private _store: Store<RootState>,
    private _balanceSkinsDialog: BalanceSkinFormDialog,
  ) {
  }

  getBalance(): Observable<GetBalanceRes> {
    if (environment.useMocks) {
      return getBalanceMock();
    } else {
      return this._http.get<GetBalanceRes>(environment.apiHost + 'banking/balance/');
    }
  }

  getTeamBalance(): Observable<GetTeamBalanceRes> {
    if (environment.useMocks) {
      return getTeamBalanceMock();
    } else {
      return this._http.get<GetTeamBalanceRes>(environment.apiHost + 'team-reward/skin/');
    }
  }

  getBalanceSkins(): Observable<GetBalanceSkinsRes> {
    if (environment.useMocks) {
      return getBalanceSkinsMock();
    } else {
      return this._http.get<GetBalanceSkinsRes>(environment.apiHost + 'structure/skins/balance/');
    }
  }

  applySkin(params: ApplyBalanceSkinReq) {
    if (environment.useMocks) {
      return of(undefined).pipe(delay(500));
    } else if (params.balanceType == 'regular') {
      return this._http.post(environment.apiHost + 'banking/balance-skin/', params);
    } else {
      return this._http.post(environment.apiHost + 'team-reward/balance-skin/', params);
    }
  }

  getTags(): Observable<GetTagsRes> {
    if (environment.useMocks) {
      const tags = getTagsMock();

      return of({ itemsCount: tags.length, tags }).pipe(delay(500));
    } else {
      return this._http.get<GetTagsRes>(
        environment.apiHost + 'news/tags/',
        { params: { articleType: 'faq' } },
      );
    }
  }
}
