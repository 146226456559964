<ng-container *puLet="breakpoints$ | async as breakpoints">
  <div
    class="pu-balances"
    [class.pu-balances_card]="isCard"
    *puLet="vm$ | async as vm"
  >

    @if (isCard) {
      <div class="pu-balances__cards ">
        <swiper
          class="pu-balances__slider"
          [config]="sliderConfig"
        >

          @for (balance of vm.balances; track balance.balanceType) {
            <pu-balance-card
              *swiperSlide
              [balance]="balance"
              (click)="openHistory(balance.balanceType)"
            >

              @switch (balance.balanceType) {
                @case ('regular') {
                  <button
                    class="lg:pu-button_s xl:pu-button_m"
                    type="button"
                    puButton
                    uiType="secondary"
                    size="m"
                    (click)="share(); $event.stopPropagation()"
                    [title]="'balances.button.sharePincoins' | translate"
                    translate="balances.button.share"
                  >
                  </button>

                  <a
                    class="lg:pu-button_s xl:pu-button_m"
                    type="button"
                    puButton
                    uiType="secondary"
                    size="m"
                    routerLink="/quests"
                    [title]="'balances.button.earnPincoins' | translate"
                    (click)="$event.stopPropagation()"
                    translate="balances.button.earn"
                  >
                  </a>
                }
                @case ('functional') {
                  <button
                    class="lg:pu-button_s xl:pu-button_m"
                    puButton
                    type="button"
                    uiType="secondary"
                    size="m"
                    (click)="openTeamBonuses(); $event.stopPropagation()"
                    translate="balances.button.sendBonus"
                  >
                  </button>
                }
              }
            </pu-balance-card>
          }
        </swiper>

        <pu-slider-controls
          class="pu-balances__slider-controls"
          [hasButtons]="breakpoints.isDesktop"
          prevControlClass="pu-balances__slider-controls_prev"
          nextControlClass="pu-balances__slider-controls_next"
        />

        <div class="swiper-pagination"></div>
      </div>

      <a
        puButton
        uiType="quaternary"
        routerLink="/faq"
        [queryParams]="{ tagId: vm.pincoinsTag?.id }"
        translate="balances.link.howToEarnPincoins"
      >
      </a>
    } @else {
      @if (breakpoints?.isTablet) {
        <div class="pu-balances__actions">
          <button
            type="button"
            puButton
            uiType="primary"
            size="l"
            (click)="share()"
            [title]="'balances.button.sharePincoins' | translate"
            translate="balances.button.share"
          >
          </button>

          <a
            type="button"
            puButton
            uiType="primary"
            size="l"
            routerLink="/quests"
            [title]="'balances.button.earnPincoins' | translate"
            translate="balances.button.earn"
          >
          </a>

          <a
            puButton
            uiType="secondary"
            size="l"
            routerLink="/faq"
            [queryParams]="{ tagId: vm.pincoinsTag?.id }"
            translate="balances.link.howToEarn"
          >
          </a>
        </div>
      }

      <p class="pu-balances__balance">
        <pu-icon
          iconName="coins"
          size="20"
        />

        <span
          class="pu-balances__balance-sum"
          [attr.translate]="'no'"
        >
          {{ vm.userBalance?.amount }}
        </span>

        <pu-icon
          iconName="pin-coin"
          size="16"
        />
      </p>
    }
  </div>
</ng-container>
