import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ImageModel } from '@pinup-teams/common';

export type CircleProgressConfig<StatusType extends string> = {
  [key in StatusType]: {
    progress: number;
    color: { start: `--${string}`; end: `--${string}` }; // should be css variable
  };
};

@Component({
  standalone: true,
  selector: 'pu-circle-progress',
  templateUrl: './circle-progress.component.html',
  styleUrls: ['./circle-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircleProgressComponent<StatusType extends string> {
  @Input({ required: true }) image: ImageModel;
  @Input({ required: true }) status: StatusType;
  @Input({ required: true }) config: CircleProgressConfig<StatusType>;
  @Input() barWidth = 3;

  readonly PI = Math.PI;
  readonly SIZE = 75;

  get styles() {
    return {
      '--color-circle-progress-start': `var(${this.config[this.status].color.start})`,
      '--color-circle-progress-end': `var(${this.config[this.status].color.end})`,
    };
  }

  get radius() {
    return (this.SIZE / 2) - (this.barWidth / 2);
  }
}
