import { createAction } from '@ngrx/store';
import { createAsyncAction, createAsyncActionNoProps } from '@pinup-teams/common';
import { Balance } from '@pt/models';

import { balanceFeatureKey } from './balances.state';
import {
  ApplyBalanceSkinReq, ApplyBalanceSkinRes, GetBalanceRes, GetBalanceSkinsRes, GetTagsRes,
} from '../models';

const init = createAction(`[${balanceFeatureKey}]: Init`);
const getBalanceReq = createAsyncActionNoProps<GetBalanceRes>(
  `[${balanceFeatureKey}]: Get balance req`,
);
const getTeamBalanceReq = createAsyncActionNoProps<GetBalanceRes>(
  `[${balanceFeatureKey}]: Get team balance req`,
);
const getBalanceSkinsReq = createAsyncActionNoProps<GetBalanceSkinsRes, Balance>(
  `[${balanceFeatureKey}]: Get balance skins req`,
);
const applySkinReq = createAsyncAction<ApplyBalanceSkinReq, ApplyBalanceSkinRes>(
  `[${balanceFeatureKey}]: Apply skin req`,
);
const getTagsReq = createAsyncActionNoProps<GetTagsRes>(`[${balanceFeatureKey}]: Get tags`);

export const BalanceActions = {
  init,
  getBalanceReq,
  getTeamBalanceReq,
  getBalanceSkinsReq,
  applySkinReq,
  getTagsReq,
};
