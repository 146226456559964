<div
  class="info-card"
  *ngIf="!expandable"
>
  <h3
    class="info-card__h3"
    *ngIf="title"
  >
    {{ title }}
  </h3>

  <div class="info-card__body">
    <ng-container *ngTemplateOutlet="projectedContent"/>
  </div>
</div>

<mat-expansion-panel
  class="info-card"
  *ngIf="expandable"
  [expanded]="expanded"
  [disabled]="disabled"
  [hideToggle]="hideToggle"
>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <h3 class="info-card__h3">
        {{ title }}
      </h3>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div class="info-card__body">
    <ng-container *ngTemplateOutlet="projectedContent"/>
  </div>
</mat-expansion-panel>

<ng-template #projectedContent>
  <ng-content/>
</ng-template>
