import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, Validators } from '@angular/forms';

import {
  ButtonModule, LetModule, OnChange, SpinnerModule, TextAreaComponentModule,
} from '@pinup-teams/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormBuilder } from '@ngneat/reactive-forms';
import { ActionCreator, ActionsSubject } from '@ngrx/store';
import { tap } from 'rxjs';
import { ofType } from '@ngrx/effects';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { UserInfoCardComponent } from '../user-info-card/user-info-card.component';
import { PatchPersonalInfoReq } from './models';

@UntilDestroy()
@Component({
  selector: 'pu-user-hobby',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    UserInfoCardComponent,
    ButtonModule,
    ReactiveFormsModule,
    TextAreaComponentModule,
    SpinnerModule,
    LetModule,
  ],
  templateUrl: './user-hobby.component.html',
  styleUrls: ['./user-hobby.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserHobbyComponent {
  @Input() @OnChange('userHobbyChanged') hobby: string;
  @Input() @OnChange('saveSucceededActionChanged') saveSucceededAction: ActionCreator;
  @Output() hobbyChanged = new EventEmitter<PatchPersonalInfoReq>();

  private _fb = inject(FormBuilder);
  private _actionsSubject = inject(ActionsSubject);
  private _cdr = inject(ChangeDetectorRef);

  editMode = false;

  form = this._fb.group({
    hobby: ['', [Validators.maxLength(200)]],
  });

  applyChanges() {
    this.hobbyChanged.emit(this.form.value);
  }

  cancel() {
    this.editMode = false;
    this.form.setValue({
      hobby: this.hobby || '',
    });
  }

  saveSucceededActionChanged(value: ActionCreator) {
    if (value) {
      this._actionsSubject
        .pipe(
          ofType(value),
          tap(() => {
            this.editMode = false;
            this._cdr.detectChanges();
          }),
          untilDestroyed(this),
        )
        .subscribe();
    }
  }

  userHobbyChanged(value: string) {
    this.form.setValue({
      hobby: value ? value : '',
    });
  }
}
