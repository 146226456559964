import { Nullable } from '@pinup-teams/common';
import * as d3 from 'd3';

import { ChartBase } from './chart-base.model';
import { defaultConfig } from './line-chart.constant';
import { DataPoint, LineChartConfig, LineConfig } from './models';

export class LineChart implements ChartBase<LineChartConfig, LineConfig> {
  config: LineChartConfig;
  data: LineConfig[];
  height: number;
  svg: d3.Selection<SVGSVGElement, undefined, null, undefined>;
  width: number;

  constructor(config: Partial<LineChartConfig> = {}, data: LineConfig[] = []) {
    this.config = { ...defaultConfig, ...config };
    this.data = data;
  }

  init(): void {
    this._initInitSizeAndSvg();
  }

  render(): SVGSVGElement {
    if (!this.width || !this.height || !this.svg) {
      throw new Error('You need to call init() before render()');
    }

    const [xScale, yScale] = this._getScaleCoordinates();
    const line = d3
      .line<DataPoint>()
      .x(d => xScale(d.xValue))
      .y(d => yScale(d.yValue));

    this._addAxes(xScale, yScale);
    this._addLegendText();
    this._addDataLines(line);

    return this.svg.node();
  }

  handleConfigChange(config: Partial<LineChartConfig>): Nullable<SVGSVGElement> {
    this.config = { ...this.config, ...config };

    this._initInitSizeAndSvg();

    if (this.data.length) {
      return this.render();
    }

    return null;
  }

  handleDataChange(data: LineConfig[]): SVGSVGElement {
    this.data = data;

    return this.render();
  }

  private _addAxes(
    xScale: d3.ScaleLinear<number, number>,
    yScale: d3.ScaleLinear<number, number>,
  ): void {
    this.svg.select('.axisX').remove();
    this.svg.select('.axisY').remove();

    this._addAxisX(xScale);
    this._addAxisY(yScale);
  }

  private _addAxisX(xScale: d3.ScaleLinear<number, number>): void {
    this.svg
      .append('g')
      .attr('class', 'axisX')
      .attr('transform', `translate(0,${this.config.height - this.config.marginBottom})`)
      .call(d3.axisBottom(xScale).ticks(this.config.numberOfTicksX).tickSizeOuter(0));
  }

  private _addAxisY(yScale: d3.ScaleLinear<number, number>): void {
    this.svg
      .append('g')
      .attr('class', 'axisY')
      .attr('transform', `translate(${this.config.marginLeft},0)`)
      .call(d3.axisLeft(yScale).ticks(this.config.numberOfTicksY).tickFormat(d3.format('.2s')))
      .call(g => g.select('.domain').remove())
      .call(g => g
        .selectAll('.tick line')
        .clone()
        .attr('x2', this.config.width - this.config.marginLeft - this.config.marginRight)
        .attr('stroke-opacity', 0.1));
  }

  private _addDataLines(line: d3.Line<DataPoint>): void {
    this.svg.selectAll('.dataPath').remove();

    this.data.forEach(i => this.svg
      .append('path')
      .attr('class', 'dataPath')
      .attr('fill', 'none')
      .attr('stroke', i.color)
      .attr('stroke-width', this.config.lineWidth)
      .attr('d', line(i.data)));
  }

  private _addLegendText(): void {
    if (this.config.legendText) {
      this.svg.call(g => g
        .append('text')
        .attr('x', this.config.marginLeft)
        .attr('y', this.config.legendTextPositionY)
        .attr('fill', 'currentColor')
        .attr('text-anchor', 'start')
        .text(this.config.legendText));
    }
  }

  private _calculateSizes(): void {
    this.width = this.config.width - this.config.marginLeft - this.config.marginRight;
    this.height = this.config.height - this.config.marginTop - this.config.marginBottom;
  }

  private _generateSvg(): void {
    this.svg = d3
      .create('svg')
      .attr('width', this.config.width)
      .attr('height', this.config.height)
      .attr('viewBox', [0, 0, this.config.width, this.config.height])
      .attr('style', 'max-width: 100%; height: auto; height: intrinsic;');
  }

  private _getScaleCoordinates(): [d3.ScaleLinear<number, number>, d3.ScaleLinear<number, number>] {
    const xMinMaxBoundaries = d3.extent(
      this.data.flatMap((line: LineConfig) => line.data),
      d => d.xValue,
    );
    const yMinMaxBoundaries = [
      0,
      d3.max(
        this.data.flatMap((line: LineConfig) => line.data),
        d => d.yValue,
      ),
    ];

    return [
      d3.scaleLinear(
        xMinMaxBoundaries,
        [this.config.marginLeft, this.width - this.config.marginRight],
      ),
      d3.scaleLinear(
        yMinMaxBoundaries,
        [this.config.height - this.config.marginBottom, this.config.marginTop],
      ),
    ];
  }

  private _initInitSizeAndSvg(): void {
    this._calculateSizes();
    this._generateSvg();
  }
}
