import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DeliveryInternationalForm } from '@pt/models';

@Component({
  selector: 'pu-delivery-view-international',
  templateUrl: './international.component.html',
  styleUrls: ['./international.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InternationalComponent {
  @Input() delivery: DeliveryInternationalForm;
}
