import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

import { TextTotalComponent } from '@pt/components';
import { TransactionStatus } from '@pt/models';

import { TransactionsTotal } from '../../store';

@Component({
  standalone: true,
  selector: 'pt-transactions-total-status',
  templateUrl: './total-status.component.html',
  styleUrls: ['./total-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TextTotalComponent],
})
export class TotalStatusComponent {
  status = input.required<TransactionStatus>();
  total = input.required<TransactionsTotal>();

  i18nHeader = computed(() => {
    switch (this.status()) {
      case TransactionStatus.Reward:
        return 'transactions.totalStatus.label.totalReceivedRewards';

      case TransactionStatus.FuncReward:
        return 'transactions.totalStatus.label.totalFunctionalRewards';

      case TransactionStatus.FromColleague:
        return 'transactions.totalStatus.label.totalIncomeFromColleagues';

      case TransactionStatus.Refund:
        return 'transactions.totalStatus.label.totalReceivedRefunds';

      case TransactionStatus.Purchase:
        return 'transactions.totalStatus.label.totalPaidPurchases';

      case TransactionStatus.ToColleague:
        return 'transactions.totalStatus.label.totalSentToColleagues';

      case TransactionStatus.Rollback:
        return 'transactions.totalStatus.label.totalReturnedRollbacks';

      case TransactionStatus.Sent:
      case TransactionStatus.Received:
      case TransactionStatus.Burned:
        return 'transactions.totalStatus.label.total';
    }
  });

  sum = computed(() => {
    switch (this.status()) {
      case TransactionStatus.Reward:
        return this.total().rewards;

      case TransactionStatus.FuncReward:
        return this.total().funcRewards;

      case TransactionStatus.FromColleague:
        return this.total().fromColleagues;

      case TransactionStatus.Refund:
        return this.total().refunds;

      case TransactionStatus.Purchase:
        return this.total().purchases;

      case TransactionStatus.ToColleague:
        return this.total().toColleagues;

      case TransactionStatus.Rollback:
        return this.total().rollback;

      case TransactionStatus.Sent:
        return this.total().sent;

      case TransactionStatus.Received:
        return this.total().received;

      case TransactionStatus.Burned:
        return this.total().burned;
    }
  });
}
