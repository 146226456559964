import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DeliveryNovaPoshtaForm } from '@pt/models';

@Component({
  selector: 'pu-delivery-view-nova-poshta',
  templateUrl: './nova-poshta.component.html',
  styleUrls: ['./nova-poshta.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NovaPoshtaComponent {
  @Input() delivery: DeliveryNovaPoshtaForm;
}
