import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '@pinup-teams/common';

@Component({
  standalone: true,
  selector: 'pt-text-total',
  templateUrl: './text-total.component.html',
  styleUrls: ['./text-total.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconModule, TranslateModule],
})
export class TextTotalComponent {
  sum = input.required<number>();
  i18nHeader = input<string>('');
  iconName = input<string>('');
}
