<div
  class="pt-achievement-history"
  [ngClass]="'pt-achievement-history_' + achievement().status"
>

  <section class="pt-achievement-history__status">
    <div class="pt-achievement-history__status-name">
      {{ ('achievementHistory.status.' + achievement().status) | translate }}
      <span class="pt-achievement-history__status-user">
        {{ name() }}
      </span>
    </div>

    <div class="pt-achievement-history__status-date">
      {{ achievement().createdAt | date:'dd MMM y HH:mm' }}
    </div>

    <div class="pt-achievement-history__status-comment">
      {{ achievement().comment }}
    </div>
  </section>

  <section class="pt-achievement-history__achievement">
    <pt-achievement
      [achievement]="achievement().achievement"
      [withName]="(breakpoints$ | async).isTabletMode"
      namePosition="left"
    />
  </section>
</div>
