<dl [class]="'pu-delivery-view pu-delivery-view-' + type">
  <div
    class="pu-delivery-view__container"
    *ngIf="type === 'admin'"
  >
    <dt>{{ 'delivery.fullName' | translate }}</dt>
    <dd>{{ order.firstName + ' ' + order.lastName }}</dd>
  </div>

  <div class="pu-delivery-view__container">
    <dt>{{ 'delivery.deliveryMethod' | translate }}</dt>
    <dd>{{ deliveryMethodName }}</dd>
  </div>

  <div class="pu-delivery-view__container">
    <dt>{{ 'delivery.phoneNumber' | translate }}</dt>
    <dd>{{ order.phone || '-' }}</dd>
  </div>

  <ng-container [ngSwitch]="order.deliveryMethod">
    <pu-delivery-view-pickup
      *ngSwitchCase="'pickup'"
      [delivery]="delivery"
    />

    <pu-delivery-view-international
      *ngSwitchCase="'international'"
      [delivery]="delivery"
    />

    <pu-delivery-view-ukrposhta
      *ngSwitchCase="'ukrposhta'"
      [delivery]="delivery"
    />

    <pu-delivery-view-nova-poshta
      *ngSwitchCase="'nova_poshta'"
      [delivery]="delivery"
    />

    <pu-delivery-view-fedex
      *ngSwitchCase="'fedex'"
      [delivery]="delivery"
    />

    <pu-delivery-view-dhl
      *ngSwitchCase="'dhl'"
      [delivery]="delivery"
    />

    <pu-delivery-view-dpd
      *ngSwitchCase="'dpd'"
      [delivery]="delivery"
    />

    <pu-delivery-view-inpost
      *ngSwitchCase="'inpost'"
      [delivery]="delivery"
    />

    <pu-delivery-view-acs
      *ngSwitchCase="'acs'"
      [delivery]="delivery"
    />

    <pu-delivery-view-gap-akis-express
      *ngSwitchCase="'gap_akis_express'"
      [delivery]="delivery"
    />
  </ng-container>

  <div class="pu-delivery-view__container">
    <dt>{{ 'delivery.additionalComment' | translate }}</dt>
    <dd>{{ order.comment || '-' }}</dd>
  </div>
</dl>
