import { combineReducers, createFeature, createReducer, on } from '@ngrx/store';
import { createAsyncReducer } from '@pinup-teams/common';
import { TeamAward } from '@pt/models';

import { GetTeamAwardsRes, GetTeamBonusRes } from '../models';
import { TeamBonusesState, teamBonusesFeatureKey } from './team-bonuses.state';
import { TeamBonusesActions } from './team-bonuses.actions';

const initialTeamAwards: TeamAward[] = [];
const teamAwardsReducer = createReducer<GetTeamAwardsRes>(
  initialTeamAwards,
  on(TeamBonusesActions.init, (state): GetTeamAwardsRes => initialTeamAwards),
  on(
    TeamBonusesActions.getTeamAwardsReq.succeededAction,
    (state, { payload }): GetTeamAwardsRes => payload,
  ),
);

const initialTeamBonus: GetTeamBonusRes['teamBonus'] = null;
const teamBonusReducer = createReducer<GetTeamBonusRes['teamBonus']>(
  initialTeamBonus,
  on(TeamBonusesActions.init, (state): GetTeamBonusRes['teamBonus'] => initialTeamBonus),
  on(
    TeamBonusesActions.getTeamBonusReq.succeededAction,
    (state, { payload }): GetTeamBonusRes['teamBonus'] => payload.teamBonus,
  ),
);

export const teamBonusesFeature = createFeature({
  name: teamBonusesFeatureKey,
  reducer: combineReducers<TeamBonusesState>({
    teamAwards: teamAwardsReducer,
    teamBonus: teamBonusReducer,
    getTeamAwardsAction: createAsyncReducer(TeamBonusesActions.getTeamAwardsReq),
    getTeamBonusAction: createAsyncReducer(TeamBonusesActions.getTeamBonusReq),
    sendAwardAction: createAsyncReducer(TeamBonusesActions.sendAwardReq),
    sendBonusAction: createAsyncReducer(TeamBonusesActions.sendBonusReq),
  }),
});
