import { ChangeDetectionStrategy, Component } from '@angular/core';

import { environment } from '@pt/environment';

@Component({
  selector: 'pt-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  readonly assetsHost = environment.assetsHost;
  readonly logoName = `${environment.shortName}-logo`;
}
