<div class="pu-delivery-view__item">
  <div class="pu-delivery-view__container">
    <dt>{{ 'delivery.country' | translate }}</dt>
    <dd>{{ delivery.country }}</dd>
  </div>

  <div class="pu-delivery-view__container">
    <dt>{{ 'delivery.postcode' | translate }}</dt>
    <dd>{{ delivery.postcode }}</dd>
  </div>

  <div class="pu-delivery-view__container">
    <dt>{{ 'delivery.region' | translate }}</dt>
    <dd>{{ delivery.regionState }}</dd>
  </div>

  <div class="pu-delivery-view__container">
    <dt>{{ 'delivery.city' | translate }}</dt>
    <dd>{{ delivery.city }}</dd>
  </div>

  <div class="pu-delivery-view__container">
    <dt>{{ 'delivery.address' | translate }}</dt>
    <dd>{{ delivery.address }}</dd>
  </div>

  <div class="pu-delivery-view__container">
    <dt>{{ 'delivery.apartment' | translate }}</dt>
    <dd>{{ delivery.apartment || '-' }}</dd>
  </div>
</div>
