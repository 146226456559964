<h4 class="pt-p2p-share-policy__header">
  {{ 'p2pSharePolicy.header' | translate }}

  <button
    class="pt-p2p-share-policy__close"
    type="button"
    puButton
    uiType="quaternary"
    size="m"
    [onlyIcon]="true"
    (click)="close(false)"
  >
    <pu-icon iconName="close-2"/>
  </button>
</h4>

<div class="pt-p2p-share-policy__body">
  <div class="pt-p2p-share-policy__text">
    <ul>
      <li>{{ 'p2pSharePolicy.text1' | translate }}</li>
      <li>{{ 'p2pSharePolicy.text2' | translate }}</li>
      <li>{{ 'p2pSharePolicy.text3' | translate }}</li>
      <li>{{ 'p2pSharePolicy.text4' | translate }}</li>
    </ul>
  </div>

  <div class="pt-p2p-share-policy__actions">
    <button
      puButton
      size="l"
      uiType="primary"
      (click)="close(true)"
    >
      {{ 'p2pSharePolicy.button.agree' | translate }}
    </button>
  </div>
</div>
