import { Observable, delay, of } from 'rxjs';
import { fillArr } from '@pinup-teams/common';
import { UserProfile } from '@pt/models';

import { GetTeamBonusRes } from '../models';

export function getTeamBonusMock(): Observable<GetTeamBonusRes> {
  return of({
    teamBonus: {
      amount: 120,
      skinType: 'functional' as const,
      skin: null,
      functionalUsers: getUsersMock(7),
    },
  }).pipe(delay(1000));
}

function getUsersMock(count = 12): UserProfile[] {
  return fillArr(count, i => ({
    id: i,
    fullName: 'Aleksander Sidorov',
    imageUrl: '/assets/images/user-avatar-mock.jpg',
    position: 'Head of QA',
    email: 'a.sidorov@pin-up.team',
  }));
}
