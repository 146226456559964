import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ButtonModule, DialogRef, IconModule } from '@pinup-teams/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'pt-p2p-share-policy',
  templateUrl: './p2p-share-policy.component.html',
  styleUrls: ['./p2p-share-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonModule, IconModule, TranslateModule],
})
export class P2pSharePolicyComponent {
  constructor(private _dialogRef: DialogRef) {
  }

  close(isAgreed: boolean): void {
    this._dialogRef.close(isAgreed);
  }
}
