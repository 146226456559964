import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { getRouteKey, OkDialog } from '@pinup-teams/common';
import { delay, map, Observable, of, switchMap, tap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { createAsyncEffect, noopAction, RouterActions } from '@pinup-teams/common';
import { RootState } from '@pt/store';
import { environment } from '@pt/environment';
import { SupportTip } from '@pt/models';

import { SupportActions } from './support.actions';
import { SupportSelectors } from './support.selectors';
import {
  GetTagsRes, GetTipsReq, GetTipsRes, SendMessageReq, SendMessageRes, supportData,
} from '../models';
import { getTipsMock, getTagsMock } from '../mocks';
import { TipsDialog } from '../components/tips/tips.dialog';

@Injectable()
export class SupportEffects {
  init$ = createEffect(() => this._actions$.pipe(
    ofType(SupportActions.init),
    concatLatestFrom(() => this._store.select(SupportSelectors.selectViewModel)),
    map(([, vm]) => (vm.tags.length ? noopAction() : SupportActions.getTagsReq.action())),
  ));

  getTagsReq$ = createEffect(() => this._actions$.pipe(
    ofType(SupportActions.getTagsReq.action),
    switchMap(() => createAsyncEffect(this.getTags(), SupportActions.getTagsReq)),
  ));

  getTipsReq$ = createEffect(() => this._actions$.pipe(
    ofType(SupportActions.getTipsReq.action),
    switchMap(action => createAsyncEffect(
      this.getTips(action.payload),
      SupportActions.getTipsReq,
    )),
  ));

  openTipsDialog$ = createEffect(
    () => this._actions$.pipe(
      ofType(SupportActions.getTipsReq.succeededAction),
      tap(action => this._tipsDialog.open(action.payload.tips)),
    ),
    { dispatch: false },
  );

  sendMessageReq$ = createEffect(() => this._actions$.pipe(
    ofType(SupportActions.sendMessageReq.action),
    switchMap(action => createAsyncEffect(
      this.sendMessage(action.payload),
      SupportActions.sendMessageReq,
    )),
  ));

  openContactOkDialog$ = createEffect(
    () => this._actions$.pipe(
      ofType(SupportActions.sendMessageReq.succeededAction),
      tap(() => this._okDialog.open({
        headerKey: 'support.contactOkDialog.header',
        messageKey: 'support.contactOkDialog.message',
        subMessageKey: 'support.contactOkDialog.subMessage',
        btnOkKey: 'support.contactOkDialog.btn.close',
        iconName: 'p',
        noCancel: true,
      })),
    ),
    { dispatch: false },
  );

  routerNavigated$ = createEffect(() => this._actions$.pipe(
    ofType(RouterActions.routerNavigatedAction, SupportActions.getTagsReq.succeededAction),
    concatLatestFrom(() => this._store.select(SupportSelectors.selectViewModel)),
    switchMap(([, vm]) => {
      const { tagCode, tipType } = supportData[getRouteKey(this._router)] || {};
      const tagId = tagCode ? vm.tags.find(tag => tag.code === tagCode)?.id : null;

      return [SupportActions.tagId({ tagId }), SupportActions.tipType({ tipType })];
    }),
  ));

  constructor(
    private _actions$: Actions,
    private _http: HttpClient,
    private _store: Store<RootState>,
    private _router: Router,
    private _tipsDialog: TipsDialog,
    private _okDialog: OkDialog,
  ) {
  }

  getTags(): Observable<GetTagsRes> {
    if (environment.useMocks) {
      const tags = getTagsMock();

      return of({ itemsCount: tags.length, tags }).pipe(delay(500));
    } else {
      return this._http.get<GetTagsRes>(
        environment.apiHost + 'news/tags/',
        { params: { articleType: 'faq' } },
      );
    }
  }

  getTips(params: GetTipsReq): Observable<GetTipsRes> {
    if (environment.useMocks) {
      const tips = getTipsMock();

      return of({ itemsCount: tips.length, tips }).pipe(delay(500));
    }

    return this._http.get<SupportTip[]>(`${environment.assetsHost}/assets/tips/${params.type}.json`)
      .pipe(
        map(tips => ({
          itemsCount: tips.length,
          tips,
        })),
      );
  }

  sendMessage(params: SendMessageReq): Observable<SendMessageRes> {
    if (environment.useMocks) {
      return of(null).pipe(delay(5000));
    } else {
      return this._http.post<SendMessageRes>(environment.apiHost + 'support/requests/', params);
    }
  }
}
