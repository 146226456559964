import { Transaction, TransactionStatus } from '@pt/models';
import { fillArr, getByOrder } from '@pinup-teams/common';

export function getTransactionsMock(count = 12, teamBonuses = false): Transaction[] {
  return fillArr(count, i => ({
    id: i,
    status: teamBonuses
      ? getByOrder(
        i,
        [TransactionStatus.Sent, TransactionStatus.Received, TransactionStatus.Burned],
      )
      : getByOrder(
        i,
        [
          TransactionStatus.Reward,
          TransactionStatus.Purchase,
          TransactionStatus.Refund,
          TransactionStatus.Rollback,
        ],
      ),
    createdAt: new Date().toString(),
    sum: i * 100,
    comment: i % 2 ? null : 'Comment',
    user: i % 2 ? null : { id: i, fullName: `User ${i}` },
  }));
}
