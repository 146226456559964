<ng-container *puLet="vm$ | async as vm">
  <ng-template #popoverTpl>
    <button
      class="pu-support__btn"
      puButton
      size="m"
      [onlyIcon]="true"
      uiType="quaternary"
      [puPopover]="popover"
      [position]="['br', 'bl']"
      [offsetY]="3"
      scrollStrategy="close"
    >

      <pu-icon iconName="faq2"/>

      <ng-template
        #popover
        let-close="close"
      >
        <div
          class="pu-support__popover"
          (click)="close()"
        >
          <a
            class="pu-support__popover-item"
            routerLink="/faq"
            [queryParams]="{tagId: vm.tagId}"
            (click)="clickItem.emit()"
            translate="support.popover.item.faq"
          >
          </a>

          @if (vm.tipType) {
            <button
              class="pu-support__popover-item"
              (click)="openTipsDialog(vm.tipType)"
              translate="support.popover.item.tips"
            >
            </button>
          }

          <button
            class="pu-support__popover-item"
            (click)="openContactDialog()"
            translate="support.popover.item.contact"
          >
          </button>
        </div>
      </ng-template>
    </button>
  </ng-template>

  <ng-template #listTpl>
    <div class="pu-support__nav">
      <pu-expansion-panel
        class="nav-expansion-panel"
        [opened]="isActive"
        isTogglableByHeader
        renderMode="eager"
      >
        <div
          class="nav-item"
          [class.nav-item_active]="isActive"
        >
          {{ 'support.btn.list' | translate }}
        </div>

        <div
          class="pu-support__nav-list"
          *puExpansionPanelContent
        >
          <a
            class="nav-item"
            routerLink="/faq"
            [queryParams]="{tagId: vm.tagId}"
            routerLinkActive="nav-item_active"

            (click)="clickItem.emit()"
            translate="support.popover.item.faq"
          >
          </a>

          @if (vm.tipType) {
            <button
              type="button"
              class="nav-item"
              (click)="openTipsDialog(vm.tipType)"
              translate="support.popover.item.tips"
            >
            </button>
          }

          <button
            type="button"
            class="nav-item"
            (click)="openContactDialog()"
            translate="support.popover.item.contact"
          >
          </button>
        </div>
      </pu-expansion-panel>
    </div>
  </ng-template>

  <ng-container *puSpinner="vm.isLoading">
    @switch (type) {
      @case ('popover') {
        <ng-container *ngTemplateOutlet="popoverTpl"/>
      }
      @case ('list') {
        <ng-container *ngTemplateOutlet="listTpl"/>
      }
    }
  </ng-container>
</ng-container>
