import { Data } from '@angular/router';

import { createAction, props } from '@ngrx/store';
import { createAsyncAction, createAsyncActionNoProps } from '@pinup-teams/common';

import { supportFeatureKey } from './support.state';
import { GetTagsRes, GetTipsReq, GetTipsRes, SendMessageReq, SendMessageRes } from '../models';

const init = createAction(`[${supportFeatureKey}]: Init`, props<{ routeData: Data }>());
const tagId = createAction(`[${supportFeatureKey}]: Tag id`, props<{ tagId: number }>());
const tipType = createAction(`[${supportFeatureKey}]: Tip type`, props<{ tipType: string }>());
const getTagsReq = createAsyncActionNoProps<GetTagsRes>(`[${supportFeatureKey}]: Get tags`);
const getTipsReq = createAsyncAction<GetTipsReq, GetTipsRes>(`[${supportFeatureKey}]: Get tips`);
const sendMessageReq = createAsyncAction<SendMessageReq, SendMessageRes>(
  `[${supportFeatureKey}]: Send message`,
);

export const SupportActions = {
  init,
  tagId,
  tipType,
  getTagsReq,
  getTipsReq,
  sendMessageReq,
};
