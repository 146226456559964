<div class="pu-support-tips__header">
  <h4
    class="pu-support-tips__header-text"
    translate="support.tipsDialog.header"
  >
  </h4>

  <button
    class="pu-support-tips__close"
    type="button"
    puButton
    size="m"
    uiType="quaternary"
    [onlyIcon]="true"
    (click)="close()"
  >
    <pu-icon
      iconName="close"
      size="16"
    />
  </button>
</div>

<div class="pu-support-tips__body">
  <pu-accordion class="pu-support-tips__accordion">
    @for (tip of tips; track tip.id) {
      <pu-expansion-panel
        class="pu-support-tips__tip"
        puAccordionItem
      >
        <div class="pu-support-tips__tip-question">
          {{ tip.question }}
        </div>

        <div
          class="pu-support-tips__tip-answer"
          *puExpansionPanelContent
        >
          {{ tip.answer }}
        </div>
      </pu-expansion-panel>
    }
  </pu-accordion>
</div>
