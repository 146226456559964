import { combineReducers, createFeature, createReducer, on } from '@ngrx/store';
import { createAsyncReducer } from '@pinup-teams/common';

import {
  achievementsFeatureKey, AchievementsState, AchievementsActions, AchievementsFilters,
} from './';
import { GetAchievementsRes } from '../models';

export const initialFilters: AchievementsFilters = {
  page: 1,
  limit: 12,
  period: null,
  achievementType: null,
  status: null,
};
const filtersReducer = createReducer<AchievementsFilters>(
  initialFilters,
  on(AchievementsActions.init, (): AchievementsFilters => ({ ...initialFilters })),
  on(
    AchievementsActions.setFilters,
    (state, action): AchievementsFilters => ({ ...state, ...action.filters }),
  ),
  on(
    AchievementsActions.changeFilters,
    (state, action): AchievementsFilters => ({ ...state, ...action.filters }),
  ),
);

const initialUserId: number = null;
const userIdReducer = createReducer<number>(
  initialUserId,
  on(AchievementsActions.init, (): number => initialUserId),
  on(AchievementsActions.setUserId, (state, action): number => action.userId),
);

const initialAchievements: GetAchievementsRes = {
  sentTotal: 0,
  receivedTotal: 0,
  itemsCount: 0,
  userAchievements: [],
};
const AchievementsReducer = createReducer<GetAchievementsRes>(
  initialAchievements,
  on(AchievementsActions.init, (): GetAchievementsRes => ({ ...initialAchievements })),
  on(
    AchievementsActions.getAchievementsReq.succeededAction,
    (state, action): GetAchievementsRes => action.payload,
  ),
);

export const achievementsFeature = createFeature({
  name: achievementsFeatureKey,
  reducer: combineReducers<AchievementsState>({
    userId: userIdReducer,
    filters: filtersReducer,
    achievements: AchievementsReducer,
    getAchievementsAction: createAsyncReducer(AchievementsActions.getAchievementsReq),
  }),
});
