import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonModule, DIALOG_DATA, DialogRef, ExpansionPanelModule, IconModule,
} from '@pinup-teams/common';
import { SupportTip } from '@pt/models';

type DialogData = { tips: SupportTip[] };

@Component({
  standalone: true,
  imports: [TranslateModule, ButtonModule, IconModule, ExpansionPanelModule],
  selector: 'pu-support-tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TipsComponent {
  private _dialogRef = inject(DialogRef);
  private _data = inject<DialogData>(DIALOG_DATA);

  tips = this._data.tips;

  close(): void {
    this._dialogRef.close();
  }
}
