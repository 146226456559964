import { AsyncPipe, DatePipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';

import { AchievementComponent } from '@pt/components';
import { AchievementStatus, HistoryAchievement } from '@pt/models';
import { TranslateModule } from '@ngx-translate/core';
import { CommonMediaQueries, IconModule, MediaScreenService } from '@pinup-teams/common';
import { combineLatest } from 'rxjs';

const UNIQUE_ACHIEVEMENT_TEXT = 'PIN-UP Global';

@Component({
  standalone: true,
  selector: 'pt-achievement-history, [ptAchievementHistory]',
  templateUrl: './achievement-history.component.html',
  styleUrls: ['./achievement-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, IconModule, AchievementComponent, DatePipe, NgClass, AsyncPipe],
})
export class AchievementHistoryComponent {
  private _mediaScreenService = inject(MediaScreenService);
  breakpoints$ = combineLatest({
    isTabletMode: this._mediaScreenService.mediaMatcher(CommonMediaQueries.MD),
  });

  achievement = input.required<HistoryAchievement>();

  name = computed(() => {
    const { status, fromUser, toUser, course, unique } = this.achievement();

    return (
      (unique && UNIQUE_ACHIEVEMENT_TEXT)
      || course?.name
      || (status === AchievementStatus.Sent ? toUser?.fullName : fromUser?.fullName)
      || ''
    );
  });
}
