import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ArticleCardSmallComponent } from './article-card-small.component';
import { ImageLoaderDirective } from '@pt/directives';

@NgModule({
  declarations: [ArticleCardSmallComponent],
  imports: [CommonModule, ImageLoaderDirective],
  exports: [ArticleCardSmallComponent],
})
export class ArticleCardSmallModule {
}
