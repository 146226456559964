import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { DialogService, INavigator, NAVIGATOR, UaParserService, WINDOW } from '@pinup-teams/common';

import { PwaSetupComponent } from './pwa-setup.component';

@Injectable({ providedIn: 'root' })
export class PwaSetupDialog {
  constructor(
    private _dialog: DialogService,
    private _uaParser: UaParserService,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(NAVIGATOR) private _navigator: INavigator,
    @Inject(WINDOW) private _window: Window,
  ) {
  }

  init(): void {
    if (!this._isStandaloneMode() && !this._isPublicPage() && this._uaParser.isMobile) {
      this._dialog.open(PwaSetupComponent, null, {
        panelClass: 'pu-overlay-panel_pwa-setup',
      });
    }
  }

  private _isStandaloneMode(): boolean {
    // https://stackoverflow.com/questions/41742390/javascript-to-check-if-pwa-or-mobile-web
    return (
      this._window.matchMedia('(display-mode: standalone)').matches
      || this._navigator.standalone
      || this._document.referrer.includes('android-app://')
    );
  }

  private _isPublicPage(): boolean {
    return this._document.location.href.includes('public');
  }
}
