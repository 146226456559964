import { Component, HostBinding, Input } from '@angular/core';

import { AvatarSize } from './models/avatar-size';
import { getHslBgColor } from './utils/get-hsl-bg-color';

@Component({
  selector: 'pu-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
  @HostBinding('class.pu-avatar') hostClass = true;
  @HostBinding('class.pu-avatar_colorful') isColorful = false;

  /**
   * If Avatar has hover
   */
  @HostBinding('class.pu-avatar_hover') @Input() isHover = false;

  @HostBinding('style.--size') sizeCssVar = '40px';
  @HostBinding('style.--border-width') borderWidth = '1px';
  @HostBinding('style.--color-avatar-bg') avatarBg = 'var(--color-avatar-bg)';

  @Input() set size(value: AvatarSize) {
    this.sizeCssVar = value + 'px';
    this.borderWidth = Number(value) > 40 ? '2px' : '1px';
  }

  /**
   * If userId is passed, it means that we should use colorful avatar
   */
  @Input() set userId(id: number) {
    if (id) {
      this.isColorful = true;
      this.avatarBg = getHslBgColor(id);
    }
  }

  @Input() image: string;
  @Input() nameFirstLetter: string;
}
