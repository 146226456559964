<div class="main-info">
  <div
    class="card-item"
    style="grid-area: email"
  >
    <div class="card-item__label">
      {{ 'userMainInfo.email' | translate }}:
    </div>
    <div class="card-item__text">
      {{ mainInfo.email }}
    </div>
  </div>
  <div
    class="card-item"
    style="grid-area: status"
  >
    <div class="card-item__label">
      {{ 'userMainInfo.status' | translate }}:
    </div>
    <div class="card-item__text">
      {{ mainInfo.status }}
    </div>
  </div>
  <div
    class="card-item"
    style="grid-area: location"
  >
    <div class="card-item__label">
      {{ 'userMainInfo.location' | translate }}:
    </div>
    <div class="card-item__text">
      {{ mainInfo.location }}
    </div>
  </div>
  <div
    class="card-item"
    style="grid-area: bday"
  >
    <div class="card-item__label">
      {{ 'userMainInfo.birthday' | translate }}:
    </div>
    <div class="card-item__text">
      {{ mainInfo.birthday | date : 'dd/MMM' }}
    </div>
  </div>
  <div
    class="card-item"
    style="grid-area: team"
  >
    <div class="card-item__label">
      {{ 'userMainInfo.team' | translate }}:
    </div>
    <div class="card-item__text">
      {{ mainInfo.team }}
    </div>
  </div>
  <div
    class="card-item"
    style="grid-area: segment"
  >
    <div class="card-item__label">
      {{ 'userMainInfo.segment' | translate }}:
    </div>
    <div class="card-item__text">
      {{ mainInfo.segment }}
    </div>
  </div>
  <div
    class="card-item"
    style="grid-area: year-start"
  >
    <div class="card-item__label">
      {{ 'userMainInfo.personalYearStart' | translate }}:
    </div>
    <div class="card-item__text">
      {{ mainInfo.personalYearStart }}
    </div>
  </div>
  <div
    class="card-item"
    style="grid-area: year-end"
  >
    <div class="card-item__label">
      {{ 'userMainInfo.personalYearEnd' | translate }}:
    </div>
    <div class="card-item__text">
      {{ mainInfo.personalYearEnd }}
    </div>
  </div>
</div>
