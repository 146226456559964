import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { delay, filter, map, Observable, of, switchMap } from 'rxjs';
import { createAsyncEffect, noopAction } from '@pinup-teams/common';
import { RootState } from '@pt/store';
import { environment } from '@pt/environment';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TeamAward } from '@pt/models';

import { TeamBonusesActions } from './team-bonuses.actions';
import {
  GetTeamAwardsRes, GetTeamBonusRes, SendAwardReq, SendAwardRes, SendBonusReq, SendBonusRes,
} from '../models';
import { getTeamAwardsMock, getTeamBonusMock } from '../mocks';
import { TeamBonusesDialog } from '../team-bonuses.dialog';
import { TeamMemberBonusForm } from '../components/bonus-form/bonus-form.component';
import { TeamMemberAwardsForm } from '../components/awards-form/awards-form.component';
import { BalanceActions } from '../../balances/store';

type TeamBonusesDialogRequest =
  | {
    type: 'bonus';
    payload: TeamMemberBonusForm[];
  }
  | {
    type: 'awards';
    payload: TeamMemberAwardsForm[];
  };

@Injectable()
export class TeamBonusesEffects {
  init$ = createEffect(() => this._actions$.pipe(
    ofType(TeamBonusesActions.init),
    switchMap(() => [
      TeamBonusesActions.getTeamAwardsReq.action(),
      TeamBonusesActions.getTeamBonusReq.action(),
    ]),
  ));

  getTeamAwardsReq$ = createEffect(() => this._actions$.pipe(
    ofType(TeamBonusesActions.getTeamAwardsReq.action),
    switchMap(() => createAsyncEffect(this.getTeamAwards(), TeamBonusesActions.getTeamAwardsReq)),
  ));

  getTeamBonusReq$ = createEffect(() => this._actions$.pipe(
    ofType(TeamBonusesActions.getTeamBonusReq.action),
    switchMap(() => createAsyncEffect(this.getTeamBonus(), TeamBonusesActions.getTeamBonusReq)),
  ));

  sendBonusReq$ = createEffect(() => this._actions$.pipe(
    ofType(TeamBonusesActions.sendBonusReq.action),
    switchMap(({ payload }) => createAsyncEffect(
      this.sendBonus(payload),
      TeamBonusesActions.sendBonusReq,
    )),
  ));

  sendAwardReq$ = createEffect(() => this._actions$.pipe(
    ofType(TeamBonusesActions.sendAwardReq.action),
    switchMap(({ payload }) => createAsyncEffect(
      this.sendAward(payload),
      TeamBonusesActions.sendAwardReq,
    )),
  ));

  openTeamBonusesDialog$ = createEffect(() => this._actions$.pipe(
    ofType(TeamBonusesActions.openTeamBonusesDialog),
    switchMap(() => this._teamBonusesDialog.open().afterClosed<TeamBonusesDialogRequest>()),
    filter(Boolean),
    map(({ type, payload }) => {
      if (type === 'bonus') {
        return TeamBonusesActions.sendBonusReq.action({ payload: { bonus: payload } });
      }

      if (type === 'awards') {
        return TeamBonusesActions.sendAwardReq.action({ payload: { awards: payload } });
      }

      return noopAction();
    }),
  ));

  updateTeamBonuses$ = createEffect(() => this._actions$.pipe(
    ofType(TeamBonusesActions.sendBonusReq.succeededAction),
    switchMap(() => [
      TeamBonusesActions.getTeamBonusReq.action(),
      BalanceActions.getTeamBalanceReq.action(),
    ]),
  ));

  constructor(
    private _actions$: Actions,
    private _http: HttpClient,
    private _store: Store<RootState>,
    private _teamBonusesDialog: TeamBonusesDialog,
    private _translate: TranslateService,
  ) {
  }

  getTeamAwards(): Observable<GetTeamAwardsRes> {
    if (environment.useMocks) {
      const teamAwards = getTeamAwardsMock();

      return of(teamAwards).pipe(
        delay(500),
        map(data => this._getNewAwards(data)),
      );
    }

    return this._http
      .get<GetTeamAwardsRes>(environment.apiHost + 'achievements/manager/reward/')
      .pipe(map(response => this._getNewAwards(response)));
  }

  getTeamBonus() {
    if (environment.useMocks) {
      return getTeamBonusMock();
    }

    return this._http.get<GetTeamBonusRes>(environment.apiHost + 'team-reward/');
  }

  sendAward(payload: SendAwardReq) {
    if (environment.useMocks) {
      return of(undefined).pipe(delay(500));
    }

    return this._http.post<SendAwardRes>(
      environment.apiHost + 'achievements/manager/reward/',
      payload,
    );
  }

  sendBonus(payload: SendBonusReq) {
    if (environment.useMocks) {
      return of(undefined).pipe(delay(500));
    }

    return this._http.post<SendBonusRes>(environment.apiHost + 'team-reward/', payload);
  }

  private _getNewAwards(data: TeamAward[]) {
    return data.map(item => {
      if (!item.awards.length) {
        item.awards.push({
          id: null,
          name: this._translate.instant('personalInfo.teamBonuses.awardsForm.notAvailable'),
        });
      }

      return item;
    });
  }
}
