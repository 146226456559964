import { inject, Injectable } from '@angular/core';

import { DialogService } from '@pinup-teams/common';

import { InterviewViewerComponent } from './interview-viewer.component';
import { ImageDisplayData } from './models';

@Injectable()
export class InterviewViewerDialog {
  private _dialogService = inject(DialogService);

  open(image: ImageDisplayData) {
    this._dialogService.open(
      InterviewViewerComponent,
      { image },
      { overlayBackdropClass: 'pu-interview-viewer-backdrop' },
    );
  }
}
