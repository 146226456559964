<div class="legend">
  @for (legend of legends(); track $index) {
    <div class="legend__item">
      <div
        class="legend__color"
        [style.background-color]="legend.color"
      ></div>
      <div class="legend__text">{{ legend.labelKey | translate }}</div>
    </div>
  }
</div>
<div #chart></div>
