import { DatePipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy, Component, computed, HostBinding, inject, input,
} from '@angular/core';

import { Transaction, TransactionStatus } from '@pt/models';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IconModule } from '@pinup-teams/common';

@Component({
  standalone: true,
  selector: 'pt-transaction, [ptTransaction]',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, IconModule, DatePipe, NgClass],
})
export class TransactionComponent {
  transaction = input.required<Transaction>();
  widget = input(false);

  @HostBinding('class.pt-transaction_widget')
  private get _widget() {
    return this.widget();
  }

  private readonly _translate = inject(TranslateService);

  readonly name = computed(() => {
    const { status, user } = this.transaction();

    if (user) {
      return user.fullName;
    } else {
      switch (status) {
        case TransactionStatus.Purchase:
          return this._translate.instant('transaction.statusName.purchase');
        case TransactionStatus.FuncReward:
          return this._translate.instant('transaction.statusName.funcReward');
        case TransactionStatus.Refund:
        case TransactionStatus.Rollback:
        case TransactionStatus.Burned:
          return '';
        default:
          return this._translate.instant('transaction.statusName.default');
      }
    }
  });
}
