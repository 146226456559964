<div class="pt-transactions-dialog__header">
  <h4 class="pt-transactions-dialog__header-text">
    {{ ('transactionsDialog.header.' + (data.forTeam ? 'teamBonuses' : 'transactions'))
    | translate }}{{ data.user.fullName ? ' - ' + data.user.fullName : '' }}
  </h4>

  <button
    class="pt-transactions-dialog__close"
    type="button"
    puButton
    size="m"
    uiType="quaternary"
    [onlyIcon]="true"
    (click)="close()"
  >
    <pu-icon
      iconName="close"
      size="16"
    />
  </button>
</div>

<div class="pt-transactions-dialog__body">
  <pt-transactions
    class="pt-transactions-dialog__transactions"
    [dialog]="true"
    [isAdmin]="data.isAdmin"
    [forTeam]="data.forTeam"
    [userId]="data.user.id"
  />
</div>
