import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DeliveryGapAkisExpressForm } from '@pt/models';

@Component({
  selector: 'pu-delivery-view-gap-akis-express',
  templateUrl: './gap-akis-express.component.html',
  styleUrls: ['./gap-akis-express.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GapAkisExpressComponent {
  @Input() delivery: DeliveryGapAkisExpressForm;
}
