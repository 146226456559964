import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { GlobalSearchArticle } from '@pt/models';

@Component({
  selector: 'pu-article-card-small',
  templateUrl: './article-card-small.component.html',
  styleUrls: ['./article-card-small.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleCardSmallComponent {
  @Input() article: GlobalSearchArticle;
}
