import { AchievementStatus, HistoryAchievement } from '@pt/models';
import { fillArr } from '@pinup-teams/common';
import { environment } from '@pt/environment';

export function getAchievementsMock(count = 12): HistoryAchievement[] {
  return fillArr(count, i => ({
    achievement: {
      id: i,
      name: `Achievement ${i}`,
      image: {
        id: i,
        url: `${environment.assetsHost}/assets/images/mock-achievement.png`,
        name: `Achievement-${i}.png`,
      },
    },
    id: i,
    status: i % 2 ? AchievementStatus.Sent : AchievementStatus.Received,
    createdAt: new Date().toString(),
    comment: i % 2 ? '' : 'Comment',
    unique: i === 2,
    fromUser: i % 2 ? { id: i, fullName: `User ${i}` } : null,
    toUser: i % 2 ? null : { id: i, fullName: `User ${i}` },
    course: i % 2 ? null : { name: `Course ${i}` },
  }));
}
