<a
  class="pt-logo"
  routerLink="/"
>
  <svg>
    <use
      [attr.xlink:href]="'#'+logoName"
      [attr.href]="'#'+logoName"
    />
  </svg>
</a>
