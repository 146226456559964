import { combineReducers, createFeature, createReducer, on } from '@ngrx/store';
import { createAsyncReducer } from '@pinup-teams/common';

import {
  transactionsFeatureKey, TransactionsState, TransactionsFilters,
} from './transactions.state';
import { TransactionsActions } from './transactions.actions';
import { GetTransactionsRes } from '../models';

export const initialFilters: TransactionsFilters = {
  page: 1,
  limit: 12,
  period: null,
  group: null,
  status: null,
};
const filtersReducer = createReducer<TransactionsFilters>(
  initialFilters,
  on(TransactionsActions.init, (): TransactionsFilters => ({ ...initialFilters })),
  on(
    TransactionsActions.setFilters,
    (state, action): TransactionsFilters => ({ ...state, ...action.filters }),
  ),
  on(
    TransactionsActions.changeFilters,
    (state, action): TransactionsFilters => ({ ...state, ...action.filters }),
  ),
);

const initialUserId: number = null;
const userIdReducer = createReducer<number>(
  initialUserId,
  on(TransactionsActions.init, (): number => initialUserId),
  on(TransactionsActions.setUserId, (state, action): number => action.userId),
);

const initialTransactions: GetTransactionsRes = {
  rewardTotal: 0,
  funcRewardTotal: 0,
  fromColleagueTotal: 0,
  refundTotal: 0,
  purchaseTotal: 0,
  toColleagueTotal: 0,
  rollbackTotal: 0,
  sentTotal: 0,
  receivedTotal: 0,
  burnedTotal: 0,
  itemsCount: 0,
  transactions: [],
};
const TransactionsReducer = createReducer<GetTransactionsRes>(
  initialTransactions,
  on(TransactionsActions.init, (): GetTransactionsRes => ({ ...initialTransactions })),
  on(
    TransactionsActions.getTransactionsReq.succeededAction,
    (state, action): GetTransactionsRes => action.payload,
  ),
);

export const transactionsFeature = createFeature({
  name: transactionsFeatureKey,
  reducer: combineReducers<TransactionsState>({
    userId: userIdReducer,
    filters: filtersReducer,
    transactions: TransactionsReducer,
    getTransactionsAction: createAsyncReducer(TransactionsActions.getTransactionsReq),
  }),
});
