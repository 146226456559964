import {
  AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild, ViewChildren,
} from '@angular/core';
import { RouterLinkActive } from '@angular/router';

import {
  BehaviorSubject, combineLatest, map, Observable, shareReplay, startWith, Subject, switchMap,
} from 'rxjs';

import { NavItem } from './models/nav-item.model';

/**
 * Navbar tab
 */
@Component({
  selector: 'pu-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavItemComponent implements AfterViewInit {
  @ViewChild(RouterLinkActive) routerLinkActiveDirective!: RouterLinkActive;
  @ViewChildren(NavItemComponent) navItemComponents: NavItemComponent[];

  /**
   * Item
   */
  @Input()
  set item(value: NavItem) {
    this.itemSubject.next(value);
  }

  private _initContentSubject = new Subject<void>();

  itemSubject = new BehaviorSubject<NavItem>(null);

  hasSubItems$ = this.itemSubject.pipe(map(({ subItems }) => subItems?.length > 0));

  isActive$ = this._initContentSubject.pipe(
    switchMap(() => this.hasSubItems$),
    switchMap(hasSubItems => (hasSubItems
      ? this._getChildIsActive()
      : this.routerLinkActiveDirective.isActiveChange.pipe(
        startWith(false),
      ))),
    // eslint-disable-next-line rxjs/no-sharereplay
    shareReplay(1),
  );

  ngAfterViewInit() {
    this._initContentSubject.next();
    this._initContentSubject.complete();
  }

  private _getChildIsActive(): Observable<boolean> {
    return combineLatest(this.navItemComponents.map(component => component.isActive$)).pipe(map(
      conditions => conditions.some(Boolean),
    ));
  }
}
