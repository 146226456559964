import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { StatusComponent } from './status.component';

@NgModule({
  declarations: [StatusComponent],
  imports: [CommonModule, TranslateModule],
  exports: [StatusComponent],
})
export class StatusModule {
}
