import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule, IconModule, DIALOG_DATA, DialogRef } from '@pinup-teams/common';

import { AchievementsHistoryModule } from '../../achievements-history.module';

@Component({
  standalone: true,
  selector: 'pt-achievements-history-dialog',
  templateUrl: './achievements-history-dialog.component.html',
  styleUrls: ['./achievements-history-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, ButtonModule, IconModule, AchievementsHistoryModule],
})
export class AchievementsHistoryDialogComponent {
  data = inject<{ userId: number }>(DIALOG_DATA);
  private readonly _dialogRef = inject(DialogRef);

  close(): void {
    this._dialogRef.close();
  }
}
