import { Injectable } from '@angular/core';

import { DialogRef, DialogService } from '@pinup-teams/common';

import { TeamBonusesComponent } from './team-bonuses.component';

@Injectable()
export class TeamBonusesDialog {
  constructor(private _dialog: DialogService) {
  }

  open(): DialogRef {
    return this._dialog.open(TeamBonusesComponent);
  }
}
