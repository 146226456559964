import { combineReducers, createFeature, createReducer, on } from '@ngrx/store';
import { createAsyncReducer } from '@pinup-teams/common';

import { balanceFeatureKey, BalanceState } from './balances.state';
import { BalanceActions } from './balances.actions';
import { GetBalanceRes, GetTagsRes, GetTeamBalanceRes } from '../models';

const initialBalance: GetBalanceRes = null;
const balanceReducer = createReducer<GetBalanceRes>(
  initialBalance,
  on(BalanceActions.init, (): GetBalanceRes => initialBalance),
  on(BalanceActions.getBalanceReq.succeededAction, (state, { payload }): GetBalanceRes => payload),
);

const initialTeamBalance: GetTeamBalanceRes['teamBalance'] = null;
const teamBalanceReducer = createReducer<GetTeamBalanceRes['teamBalance']>(
  initialTeamBalance,
  on(BalanceActions.init, (): GetTeamBalanceRes['teamBalance'] => initialTeamBalance),
  on(
    BalanceActions.getTeamBalanceReq.succeededAction,
    (state, { payload }): GetTeamBalanceRes['teamBalance'] => payload.teamBalance,
  ),
);

const tagsReducer = createReducer<GetTagsRes>(
  { itemsCount: 0, tags: [] },
  on(BalanceActions.getTagsReq.succeededAction, (state, action): GetTagsRes => action.payload),
);

export const balanceFeature = createFeature({
  name: balanceFeatureKey,
  reducer: combineReducers<BalanceState>({
    balance: balanceReducer,
    teamBalance: teamBalanceReducer,
    tags: tagsReducer,
    getBalanceAction: createAsyncReducer(BalanceActions.getBalanceReq),
    getTeamBalanceAction: createAsyncReducer(BalanceActions.getTeamBalanceReq),
    getBalanceSkinsAction: createAsyncReducer(BalanceActions.getBalanceSkinsReq),
    applyBalanceSkinAction: createAsyncReducer(BalanceActions.applySkinReq),
    getTagsAction: createAsyncReducer(BalanceActions.getTagsReq),
  }),
});
