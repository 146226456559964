import { AsyncPipe } from '@angular/common';
import {
  Component, OnInit, ChangeDetectionStrategy, HostBinding, booleanAttribute, input, inject,
  DestroyRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

import { DonutTotalComponent, TextTotalComponent } from '@pt/components';
import {
  ButtonModule,
  DictionaryItem,
  EmptyComponent,
  initFormGroup,
  LetDirective,
  SelectComponent,
  SpinnerDirective,
  WithAllOptionPipe,
} from '@pinup-teams/common';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { FormBuilder } from '@ngneat/reactive-forms';
import { changePage, subscribeOnControls, subscribeOnRouteParams } from '@pinup-teams/common';
import { RootState } from '@pt/store';
import { TranslateModule } from '@ngx-translate/core';
import { DictionariesService } from '@pt/services';
import { AchievementType, AchievementStatus } from '@pt/models';
import { SwiperOptions } from 'swiper';
import { SwiperModule } from 'swiper/angular';

import {
  AchievementHistoryComponent,
} from './components/achievement-history/achievement-history.component';
import {
  AchievementsActions,
  AchievementsSelectors,
  AchievementsViewModel,
  routeFiltersSettings,
  initialFilters,
  AchievementsFilters,
} from './store';

@Component({
  standalone: true,
  selector: 'pt-achievements-history',
  templateUrl: './achievements-history.component.html',
  styleUrls: ['./achievements-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SpinnerDirective,
    TranslateModule,
    ButtonModule,
    AsyncPipe,
    LetDirective,
    SelectComponent,
    AchievementHistoryComponent,
    EmptyComponent,
    MatPaginator,
    DonutTotalComponent,
    TextTotalComponent,
    WithAllOptionPipe,
    SwiperModule,
  ],
})
export class AchievementsHistoryComponent implements OnInit {
  userId = input<number>();
  dialog = input(false, { transform: booleanAttribute });

  @HostBinding('class.pt-achievements-history_dialog')
  private get _dialog() {
    return this.dialog();
  }

  private readonly destroyRef = inject(DestroyRef);
  private readonly _route = inject(ActivatedRoute);
  private readonly _store = inject(Store<RootState>);
  private readonly _fb = inject(FormBuilder);
  private readonly _dictionaries = inject(DictionariesService);

  readonly vm$: Observable<AchievementsViewModel>
    = this._store.select(AchievementsSelectors.selectViewModel);

  readonly filtersForm = initFormGroup<AchievementsFilters>(
    this._store.select(AchievementsSelectors.selectFilters),
    this._fb.group<AchievementsFilters>({ ...initialFilters }),
    this,
  );

  readonly AchievementType = AchievementType;
  readonly AchievementStatus = AchievementStatus;

  readonly periods = this._dictionaries.periods;
  readonly types = this._dictionaries.achievementTypes;
  readonly statuses = this._dictionaries.achievementStatuses;

  readonly sliderConfig: SwiperOptions = {
    slidesPerView: 'auto',
    grabCursor: true,
    navigation: {
      prevEl: '.pt-achievements-history__swiper-control-prev',
      nextEl: '.pt-achievements-history__swiper-control-next',
    },
  };

  ngOnInit(): void {
    this._store.dispatch(AchievementsActions.init({ routeData: { userId: this.userId() } }));
    subscribeOnRouteParams(<any> this, routeFiltersSettings);
    subscribeOnControls(<any> this, routeFiltersSettings);
  }

  changePage(event: PageEvent): void {
    changePage(<any> this, event);
  }

  changeType(type: DictionaryItem<AchievementType>): void {
    this.filtersForm.patchValue({ achievementType: type.id, status: null });
  }
}
