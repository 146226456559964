import { AsyncState } from '@pinup-teams/common';
import { TeamAward, TeamBonus } from '@pt/models';

import { GetTeamAwardsRes, GetTeamBonusRes, SendAwardRes, SendBonusRes } from '../models';

export const teamBonusesFeatureKey = 'Team Bonuses';

export interface TeamBonusesState {
  teamAwards: TeamAward[];
  teamBonus: TeamBonus;
  getTeamAwardsAction: AsyncState<GetTeamAwardsRes>;
  getTeamBonusAction: AsyncState<GetTeamBonusRes>;
  sendAwardAction: AsyncState<SendAwardRes>;
  sendBonusAction: AsyncState<SendBonusRes>;
}
