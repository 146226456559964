import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'pu-user-info-card',
  standalone: true,
  templateUrl: './user-info-card.component.html',
  styleUrls: ['./user-info-card.component.scss'],
  imports: [CommonModule, MatExpansionModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInfoCardComponent {
  @Input() expandable = false;
  @Input() title = '';
  @Input() expanded = true;
  @Input() disabled = false;
  @Input() hideToggle = false;
}
